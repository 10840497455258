import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Snackbar } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { cpf, cnpj } from 'cpf-cnpj-validator';
import Apis from "../../../Apis";
import Loading from "../../../loading/Loading";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import swal from "sweetalert";
const AlterarEmpresas = () => {
    const [cep, setCep] = useState('');
    const [cidade, setCidade] = useState('');
    const [bairro, setBairro] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [uf, setUf] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [razao, setRazao] = useState('');
    const [fantasia, setFantasia] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [ieRg, setIeRg] = useState('');
    const [mapaRotas, setMapaRotas] = useState('');
    const [tokenEmpresa, setTokenEmpresa] = useState('');
    const [ddd, setDdd] = useState('');
    const [financeiro, setFinanceiro] = useState('');
    const [desconto, setDesconto] = useState('');
    const [vendaClienteAutorizado, setVendaClienteAutorizado] = useState('');
    const [alterarPrecoVenda, setAlterarPrecoVenda] = useState('');
    const [vendaSemEstoque, setVendaSemEstoque] = useState('');
    const [validCpfCnpj, setValidCpfCnpj] = useState();
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleFinanceiro = (e) => {
        setFinanceiro(e.target.value)
    }
    const handleDesconto = (e) => {
        setDesconto(e.target.value)
    }
    const handleCep = (e) => {
        setCep(e.target.value)
    }
    const handleCidade = (e) => {
        setCidade(e.target.value)
    }
    const handleBairro = (e) => {
        setBairro(e.target.value)
    }
    const handleLogradouro = (e) => {
        setLogradouro(e.target.value)
    }
    const handleUf = (e) => {
        setUf(e.target.value)
    }
    const handleComplemento = (e) => {
        setComplemento(e.target.value)
    }

    const handleNumero = (e) => {
        setNumero(e.target.value)
    }
    const handleRazao = (e) => {
        setRazao(e.target.value)
    }
    const handleFantasia = (e) => {
        setFantasia(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleVendaClienteAutorizado = (e) => {
        setVendaClienteAutorizado(e.target.value)
    }
    const handleAlterarPrecoVenda = (e) => {
        setAlterarPrecoVenda(e.target.value)
    }
    const handleVendaSemEstoque = (e) => {
        setVendaSemEstoque(e.target.value)
    }
    const handleTelefone = (e) => {
        if (telefone === '' && ddd !== '') {
            setTelefone(`(${ddd}) ` + e.target.value)
        } else {
            setTelefone(e.target.value)
        }
    }
    const handleCpfCnpj = (e) => {
        setCpfCnpj(e.target.value)
    }
    const handleIeRg = (e) => {
        setIeRg(e.target.value)
    }
    const handleMapaRotas = (e) => {
        setMapaRotas(e.target.value)
    }

    useEffect(() => {
        if (cep.length === 8 && cep.length > 7) {
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
                .then((response) => response.json())
                .then((result) => {
                    setCidade(result.localidade)
                    setBairro(result.bairro)
                    setLogradouro(result.logradouro)
                    setUf(result.uf)
                    setComplemento(result.complemento)
                    setDdd(result.ddd)
                })
        }
        if (cpfCnpj.length >= 11 && cpfCnpj.length <= 18) {
            const resultValid = cpf.isValid(cpfCnpj);
            const resultValidCnpj = cnpj.isValid(cpfCnpj);
            if (resultValid || resultValidCnpj) {
                setValidCpfCnpj(true)
            }
            if (!resultValid && !resultValidCnpj) {
                setValidCpfCnpj(false)
            }
        }
    }, [cep, cpfCnpj])

    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` },
            body: JSON.stringify({ "id": localStorage.getItem("id") })
        };
        fetch(Apis.urlListarOneEmpresas, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.retorno.sucesso) {
                    console.log(result);
                    setRazao(result.registros[0].nome)
                    setFantasia(result.registros[0].fantasia)
                    setCep(result.registros[0].cep)
                    setLogradouro(result.registros[0].logradouro)
                    setNumero(result.registros[0].numero)
                    setBairro(result.registros[0].bairro)
                    setComplemento(result.registros[0].complemento)
                    setCidade(result.registros[0].cidade)
                    setUf(result.registros[0].uf)
                    setEmail(result.registros[0].email)
                    setTelefone(result.registros[0].telefone)
                    setCpfCnpj(result.registros[0].cnpj)
                    setIeRg(result.registros[0].ie)
                    setMapaRotas(result.registros[0].link_mapa_google)
                    setTokenEmpresa(result.registros[0].token)
                    setFinanceiro(result.registros[0].financeiro_venda)
                    setDesconto(result.registros[0].permite_desconto)
                    setVendaClienteAutorizado(result.registros[0].venda_cliente_autorizado)
                    setAlterarPrecoVenda(result.registros[0].altera_preco_pedido)
                    setVendaSemEstoque(result.registros[0].venda_sem_estoque)
                    setLoading(false);
                } else {
                    alertaErro(result.retorno.mensagem);
                    setOpen(true);
                }
            })
    }, [])

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }

    const handleSubmit = () => {
        if (validCpfCnpj === false || cpfCnpj.length < 11) {
            alertaErro("CPF/CNPJ inválido!")
            setOpen(true);
        }
        if (razao === '' || cpfCnpj === '') {
            alertaErro('Preencha os campos obrigatórios(*)')
            setOpen(true);
        }
        if (razao !== '' && cpfCnpj !== '' && validCpfCnpj === true) {
            const data = { "altera_preco_pedido": alterarPrecoVenda, "venda_sem_estoque": vendaSemEstoque, "venda_cliente_autorizado": vendaClienteAutorizado, "id": localStorage.getItem("id"), "permite_desconto": desconto, "financeiro_venda": financeiro, "nome": razao, "fantasia": fantasia, "logradouro": logradouro, "numero": numero, "cep": cep.length < 8 ? "1000000" : cep, "bairro": bairro, "complemento": complemento, "cidade": cidade, "uf": uf, "email": email, "fone": telefone, "cnpj": cpfCnpj, "ie": ieRg, "link_mapa_google": mapaRotas };
            console.log(data);
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` },
                body: JSON.stringify(data)
            };
            fetch(Apis.urlAlterarEmpresa, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if (result.retorno.sucesso) {
                        navigate(`/alerta-sucesso/${result.retorno.mensagem}&listar-empresas`);
                    } else {
                        alertaErro(result.retorno.mensagem);
                    }
                })
        }
    }
    if (loading) {
        return (
            <Loading />
        )
    }
    else {
        return (
            <>
                <div style={{ padding: 15, paddingTop: 0, marginTop: 80 }}>
                    <div className="flex items-center w-full justify-center">
                        <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
                            <div className="flex flex-col items-center space-y-4">
                                <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">Alterar Empresa</h1>
                                <p className="text-sm text-gray-500 text-center w-6/6">Informe os dados solicitados para realizar a alteração da empresa.</p>
                                <label className="w-full text-left">CPF/CNPJ:
                                    <input type="text" placeholder="CPF/CNPJ*" onChange={handleCpfCnpj} value={cpfCnpj} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left">IE/RG:
                                    <input type="text" placeholder="IE/RG" onChange={handleIeRg} value={ieRg} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left text-left">Razão social:
                                    <input type="text" placeholder="Razão Social*" onChange={handleRazao} value={razao} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left">Nome fantasia:
                                    <input type="text" placeholder="Nome Fantasi" onChange={handleFantasia} value={fantasia} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left">CEP:
                                    <input type="text" placeholder="CEP" onChange={handleCep} value={cep} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left">Logradouro
                                    <input type="text" placeholder="Logradouro" onChange={handleLogradouro} value={logradouro} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left">Número:
                                    <input type="text" placeholder="Número" onChange={handleNumero} value={numero} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left">Bairro:
                                    <input type="text" placeholder="Bairro" onChange={handleBairro} value={bairro} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left">Complemento:
                                    <input type="text" placeholder="Complemento" onChange={handleComplemento} value={complemento} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left">Cidade:
                                    <input type="text" placeholder="Cidade" onChange={handleCidade} value={cidade} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left">UF:
                                    <input type="text" placeholder="UF" onChange={handleUf} value={uf} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left">Email:
                                    <input type="text" placeholder="Email" onChange={handleEmail} value={email} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full text-left">Telefone:
                                    <input type="text" placeholder="Telefone 00000-0000" onChange={handleTelefone} value={`${telefone}`} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                {/* <label className="w-full text-left">Link do Mapa de Rotas:
                                    <input type="text" placeholder="Link do Mapa de Rotas" onChange={handleMapaRotas} value={mapaRotas} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                                </label> */}
                                <label className="w-full text-left">Informar Financeiro na Venda
                                    <select className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" onChange={handleFinanceiro} value={financeiro}>
                                        <option value='0'>Permitir Informar Financeiro Na Venda</option>
                                        <option value='1'>Não Permitir Informar Financeiro Na Venda</option>
                                    </select>
                                </label>
                                <label className="w-full text-left">Permitir Desconto no Pedido
                                    <select className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" onChange={handleDesconto} value={desconto}>
                                        <option value='0'>Permitir Desconto</option>
                                        <option value='1'>Não Permitir Desconto</option>
                                    </select>
                                </label>
                                <label className="w-full text-left">Permitir venda somente para cliente autorizado
                                    <select className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" onChange={handleVendaClienteAutorizado} value={vendaClienteAutorizado}>
                                        <option value='0'>Permitir venda para todos</option>
                                        <option value='1'>Somente para clientes autorizados</option>
                                    </select>
                                </label>
                                <label className="w-full text-left">Permitir venda sem estoque
                                    <select className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" onChange={handleVendaSemEstoque} value={vendaSemEstoque}>
                                        <option value='0'>Não permitir venda sem estoque</option>
                                        <option value='1'>Permitir venda sem estoque</option>
                                    </select>
                                </label>
                                <label className="w-full text-left">Permitir alterar preço na venda
                                    <select className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" onChange={handleAlterarPrecoVenda} value={alterarPrecoVenda}>
                                        <option value='0'>Não Permitir</option>
                                        <option value='1'>Permitir Alterar Preço</option>
                                        <option value='2'>Permitir Alterar Somente Para Mais</option>
                                    </select>
                                </label>
                                <label className="w-full text-left">Token da empresa:
                                    <input type="text" placeholder="Token da empresa" disabled value={tokenEmpresa} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <button onClick={handleSubmit} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Alterar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default AlterarEmpresas;