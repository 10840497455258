import React, { useState } from 'react';
import { Box, Button, Container, Link, Typography, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Apis from '../Apis';
import swal from "sweetalert";
import { useNavigate } from 'react-router-dom';
import Loading from '../loading/Loading';

const Login = (props) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [validaUsuario, setValidaUsuario] = useState(false);
    const [validaSenha, setValidaSenha] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };
    const handleUsuario = (e) => {
        setUsuario(e.target.value);
    }
    const handleSenha = (e) => {
        setSenha(e.target.value);
    }

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }

    const handleLogin = () => {
        setValidaSenha(false)
        setValidaUsuario(false)
        if(senha === ''){
            setValidaSenha(true)
            alertaErro("Preencha todos os campos")
        }
        if(usuario === ''){
            setValidaUsuario(true)
            alertaErro("Preencha todos os campos")
        }
        if(senha !== '' && usuario !== ''){
            setLoading(true)
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({'email': usuario, "senha": senha})
            };
            fetch(Apis.urlLogin, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    console.log(result);
                    if(result.retorno.sucesso){
                        localStorage.setItem('token', result.retorno.token)
                        localStorage.setItem('email', result.retorno.email);
                        localStorage.setItem('nome', result.retorno.nome);
                        localStorage.setItem('id', result.retorno.id);
                        localStorage.setItem('imagemLogin', result.retorno.imagem_login);
                        localStorage.setItem('usaMerchant', result.retorno.usa_merchant);
                        props.setToken(localStorage.getItem('token'))
                        navigate('/home')
                    }else{
                        alertaErro(result.retorno.mensagem);
                    }
                    setLoading(false)
                })

        }
    }
    const handleRedirect = (pagina) => {
        navigate(pagina);
    }
    if(loading){
        return(
            <Loading/>
        )
    }
    return (
        <>
            <Box component="main" sx={{ alignItems: 'center', display: 'flex', flexGrow: 1, minHeight: '100vh', backgroundColor: '#ffffc', padding: '10px'}}>
                <Container maxWidth="sm" sx={{backgroundColor: '#000000', padding: '30px', borderRadius: '10px', boxShadow: '0 0 0.5em #000000'}}>
                    <Box sx={{ my: 0 }}>
                        <Typography style={{display: 'flex', justifyContent: 'left', alignItems: 'center', color: 'white', marginBottom: '10px'}}>
                            <LockOpenIcon style={{fontSize: '45px'}}/>
                            <Typography color="white" variant="h4">Ge online</Typography>
                        </Typography>
                        <Typography className='text-left mt-2 text-white' gutterBottom variant="body2" style={{marginBottom: '10px'}}>Faça login na plataforma interna utilizando suas credenciais</Typography>
                    </Box>
                    <hr style={{backgroundColor: 'white'}}/>
                    <FormControl sx={{marginTop: '40px', width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password" sx={{color: validaUsuario && 'red'}}>Usuário</InputLabel>
                        <OutlinedInput type='text' error={validaUsuario && true} sx={{backgroundColor: 'white'}} onChange={handleUsuario} value={usuario}
                            endAdornment={
                                <InputAdornment position="end">
                                    <AccountCircle color={validaUsuario ? 'error' : ''}/>
                                </InputAdornment>
                            }
                            label="Usuário"
                        />
                    </FormControl>
                    <FormControl sx={{marginTop: '20px', width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password" sx={{color: validaSenha && 'red'}}>Senha</InputLabel>
                        <OutlinedInput type={showPassword ? 'text' : 'password'} error={validaSenha && true} sx={{backgroundColor: 'white'}} onChange={handleSenha} value={senha}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                        {showPassword ? <VisibilityOff  color={validaSenha ? 'error' : ''}/> : <Visibility  color={validaSenha ? 'error' : ''}/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Senha"
                        />
                    </FormControl>
                    <Box sx={{ py: 2 }}>
                    <Button color="primary" fullWidth size="large" onClick={handleLogin} variant="contained">ENTRAR</Button>
                    </Box>
                    <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography color="textSecondary" variant="body2">
                            <Link variant="subtitle2" onClick={() => handleRedirect('/alterar-senha')} underline="hover" sx={{ cursor: 'pointer', color: 'white' }}>Esqueceu a senha</Link>
                        </Typography>
                        {/* <Typography color="textSecondary" variant="body2">
                            <Link variant="subtitle2" underline="hover" sx={{ cursor: 'pointer', color: 'white' }}>Criar uma conta </Link>
                        </Typography> */}
                    </Box>
                </Container>
            </Box>
        </>
    );
};
export default Login;
