import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Snackbar } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { cpf, cnpj } from 'cpf-cnpj-validator';
import Loading from "../../loading/Loading";
import Apis from "../../Apis";
import swal from "sweetalert";

const AlterarCliente = () => {
    const [cep, setCep] = useState('');
    const [cidade, setCidade] = useState('');
    const [bairro, setBairro] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [uf, setUf] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [razao, setRazao] = useState('');
    const [fantasia, setFantasia] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [ieRg, setIeRg] = useState('');
    const [ddd, setDdd] = useState('');
    const [validCpfCnpj, setValidCpfCnpj] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const parametro = useParams();
    const [rotaId, setRotaId] = useState('');
    const [rotasServer, setRotasServer] = useState([]);
    const handleCep = (e) => {
        setCep(e.target.value)
    }
    const handleCidade = (e) => {
        setCidade(e.target.value)
    }
    const handleBairro = (e) => {
        setBairro(e.target.value)
    }
    const handleLogradouro = (e) => {
        setLogradouro(e.target.value)
    }
    const handleUf = (e) => {
        setUf(e.target.value)
    }
    const handleComplemento = (e) => {
        setComplemento(e.target.value)
    }

    const handleNumero = (e) => {
        setNumero(e.target.value)
    }
    const handleRazao = (e) => {
        setRazao(e.target.value)
    }
    const handleFantasia = (e) => {
        setFantasia(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleTelefone = (e) => {
        if (telefone === '' && ddd !== '') {
            setTelefone(`(${ddd}) ` + e.target.value)
        } else {
            setTelefone(e.target.value)
        }
    }
    const handleCpfCnpj = (e) => {
        setCpfCnpj(e.target.value)
    }
    const handleIeRg = (e) => {
        setIeRg(e.target.value)
    }
    const handleRotaId = (e) => {
        setRotaId(e.target.value)
    }

    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` },
            body: JSON.stringify({ "id": parametro.id })
        };
        fetch(Apis.urlListarOneClientes, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.retorno.sucesso) {
                    setCep(result.registros[0].cep);
                    setCidade(result.registros[0].cidade);
                    setBairro(result.registros[0].bairro);
                    setLogradouro(result.registros[0].logradouro);
                    setUf(result.registros[0].uf);
                    setNumero(result.registros[0].numero);
                    setComplemento(result.registros[0].complemento);
                    setRazao(result.registros[0].nome);
                    setFantasia(result.registros[0].apelido);
                    setEmail(result.registros[0].email);
                    setTelefone(result.registros[0].fone1);
                    setCpfCnpj(result.registros[0].cnpj_cpf);
                    setIeRg(result.registros[0].ie_rg);
                    setRotaId(result.registros[0].rota_id)
                    setLoading(false);
                } else {
                    navigate(`/alerta-sucesso/${result.retorno.mensagem}&listar-clientes`);
                }
            })
    }, [])

    useEffect(() => {
        if (cep.length === 8 && cep.length > 7) {
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
                .then((response) => response.json())
                .then((result) => {
                    setCidade(result.localidade)
                    setBairro(result.bairro)
                    setLogradouro(result.logradouro)
                    setUf(result.uf)
                    setComplemento(result.complemento)
                    setDdd(result.ddd)
                })
        }
        if (cpfCnpj.length >= 11 && cpfCnpj.length <= 18) {
            const resultValid = cpf.isValid(cpfCnpj);
            const resultValidCnpj = cnpj.isValid(cpfCnpj);
            if (resultValid || resultValidCnpj) {
                setValidCpfCnpj(true)
            }
            if (!resultValid && !resultValidCnpj) {
                setValidCpfCnpj(false)
            }
        }
    }, [cep, cpfCnpj])

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }

    const handleSubmit = () => {
        if (validCpfCnpj === false || cpfCnpj.length < 11) {
            alertaErro("CPF/CNPJ inválido!")
        }
        if (razao === '' || cpfCnpj === '') {
            alertaErro('Preencha os campos obrigatórios(*)')
        }
        if (razao !== '' && cpfCnpj !== '' && validCpfCnpj === true) {
            const data = { "rota_id": rotaId, "id": parametro.id, "nome": razao, "apelido": fantasia, "logradouro": logradouro, "numero": numero, "cep": cep, "bairro": bairro, "complemento": complemento, "cidade": cidade, "uf": uf, "email": email, "fone1": telefone, "cnpj_cpf": cpfCnpj, "ie_rg": ieRg };
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` },
                body: JSON.stringify(data)
            };
            fetch(Apis.urlAlterarCliente, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if (result.retorno.sucesso) {
                        navigate(`/alerta-sucesso/${result.retorno.mensagem}&listar-clientes`);
                    } else {
                        alertaErro(result.retorno.mensagem);
                    }
                })
        }
    }
    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` },
            body: JSON.stringify({})
        };
        fetch(Apis.urlListarRotas, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setRotasServer(result.registros)
            })
    }, []);
    if (loading) {
        return (
            <Loading />
        )
    }
    else {
        return (
            <>
                <div style={{ padding: 15, paddingTop: 0, marginTop: 80 }}>
                    <div className="flex items-center w-full justify-center">
                        <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
                            <div className="flex flex-col items-center space-y-4">
                                <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">Alteração de Cliente</h1>
                                <p className="text-sm text-gray-500 text-center w-6/6">Informe os dados solicitados para realizar a alteração do cliente.</p>
                                <label className="w-full">Razão social:
                                    <input type="text" placeholder="Razão Social*" onChange={handleRazao} value={razao} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">Nome fantasia:
                                    <input type="text" placeholder="Nome Fantasi" onChange={handleFantasia} value={fantasia} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">CEP:
                                    <input type="text" placeholder="CEP" onChange={handleCep} value={cep} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">Logradouro
                                    <input type="text" placeholder="Logradouro" onChange={handleLogradouro} value={logradouro} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">Número:
                                    <input type="text" placeholder="Número" onChange={handleNumero} value={numero} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">Bairro:
                                    <input type="text" placeholder="Bairro" onChange={handleBairro} value={bairro} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">Complemento:
                                    <input type="text" placeholder="Complemento" onChange={handleComplemento} value={complemento} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">Cidade:
                                    <input type="text" placeholder="Cidade" onChange={handleCidade} value={cidade} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">UF:
                                    <input type="text" placeholder="UF" onChange={handleUf} value={uf} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">Email:
                                    <input type="text" placeholder="Email" onChange={handleEmail} value={email} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">Telefone:
                                    <input type="text" placeholder="Telefone 00000-0000" onChange={handleTelefone} value={`${telefone}`} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">CPF/CNPJ:
                                    <input type="text" placeholder="CPF/CNPJ*" onChange={handleCpfCnpj} value={cpfCnpj} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                <label className="w-full">IR/RG:
                                    <input type="text" placeholder="IE/RG" onChange={handleIeRg} value={ieRg} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                                </label>
                                {rotasServer?.length > 0 &&
                                    <label className="w-full">Rota:
                                        <select onChange={handleRotaId} value={rotaId} className="border-2 uppercase border-gray-300 rounded-lg w-full h-12 px-4">
                                            {rotasServer?.map((rota, index) => (
                                                <option className="uppercase" key={rota.id} value={rota.id}>{rota.nome}</option>
                                            ))}
                                        </select>
                                    </label>
                                }
                                <button onClick={handleSubmit} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Alterar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default AlterarCliente;