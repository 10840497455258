import React from "react";
import { Routes, Route } from "react-router-dom";
import ListaProdutos from "../lista/produtos/ListaProdutos";
import ListaClientes from "../lista/clientes/ListaClientes";
import CadastrarProduto from "../cadastro/produto/CadastrarProduto";
import AlterarProduto from "../alterar/produto/AlterarProduto";
import CadastrarCliente from "../cadastro/cliente/CadastrarCliente";
import AlterarCliente from "../alterar/cliente/AlterarCliente";
import ListaPedido from "../lista/pedido/ListaPedido";
import CadastrarPedido from "../cadastro/pedido/CadastrarPedido";
import Error from "../error/Error";
import AlterarPedido from "../alterar/pedido/AlterarPedido";
import CadastrarUnidade from "../cadastro/unidade/CadastrarUnidade";
import ListaUnidade from "../lista/unidade/ListaUnidade";
import AlterarUnidade from "../alterar/unidade/AlterarUnidade";
import CadastrarRepresentante from "../cadastro/representante/CadastrarRepresentante";
import ListaRepresentante from "../lista/representante/ListaRepresentante";
import AlterarRepresentante from "../alterar/representante/AlterarRepresentante";
import AlterarEmpresas from "../cadastro/empresa/alterar/AlterarEmpresas";
import ListarEmpresa from "../lista/empresa/ListarEmpresa";
import CadastrarEmpresa from "../cadastro/empresa/cadastrar/CadastrarEmpresa";
import CadastrarFormaPagamento from "../cadastro/formaPagamento/CadastrarFormaPagamento";
import ListaFormaPagamento from "../lista/formaPagamento/ListaFormaPagamento";
import AlterarFormaPagamento from "../alterar/formaPagamento/AlterarFormaPagamento";
import Home from "../Home";
import Dashboard from "../lista/dashboard/Dashboard";
import ListaVisitas from "../lista/visitas/ListarVisitas";
import ListaRotas from "../lista/rotas/ListaRotas";
import CadastrarRotas from "../cadastro/rotas/CadastrarRotas";
import AlterarRota from "../alterar/rota/AlterarRota";
import { AlertaSucesso } from "../modal/AlertaSucesso";
export default function Rotas(){
    return(
            <Routes>
                {/* pagina 404 */}
                    <Route path="*" element={<Error/>}/>
                {/* fim pagina 404 */}

                {/* pagina home */}
                    <Route path='/home' element={<Home/>}/>
                    <Route path='/' element={<Home/>}/>
                {/* fim pagina home */}

                {/* cadastros */}
                    <Route path="/cadastrar-empresa" element={<CadastrarEmpresa/>}/>
                    <Route path="/cadastrar-produtos" element={<CadastrarProduto/>}/>
                    <Route path="/cadastrar-clientes" element={<CadastrarCliente/>}/>
                    <Route path="/cadastrar-representante/:id" element={<CadastrarRepresentante/>}/>
                    <Route path="/cadastrar-pedido" element={<CadastrarPedido/>}/>
                    <Route path="/cadastrar-unidade/:id" element={<CadastrarUnidade/>}/>
                    <Route path="/cadastrar-rotas" element={<CadastrarRotas/>}/>
                    <Route path="/cadastrar-forma-pagamento" element={<CadastrarFormaPagamento/>}/>
                {/* fim cadastros */}

                {/* listagem */}
                    <Route path="/listar-produtos" element={<ListaProdutos/>}/>
                    <Route path="/listar-clientes" element={<ListaClientes/>}/>
                    <Route path="/listar-pedidos" element={<ListaPedido/>}/>
                    <Route path="/listar-visitas" element={<ListaVisitas/>}/>
                    <Route path="/listar-rotas" element={<ListaRotas/>}/>
                    <Route path="/listar-unidades" element={<ListaUnidade/>}/>
                    <Route path="/listar-empresas" element={<ListarEmpresa/>}/>
                    <Route path="/listar-representantes" element={<ListaRepresentante/>}/>
                    <Route path="/listar-forma-pagamento" element={<ListaFormaPagamento/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                {/* fim listagem */}

                {/* alteração */}
                    <Route path="/alterar-produto/:id" element={<AlterarProduto/>}/>
                    <Route path="/alterar-cliente/:id" element={<AlterarCliente/>}/>
                    <Route path="/alterar-pedido/:id" element={<AlterarPedido/>}/>
                    <Route path="/alterar-unidade/:id" element={<AlterarUnidade/>}/>
                    <Route path="/alterar-rotas/:id" element={<AlterarRota/>}/>
                    <Route path="/alterar-representante/:id" element={<AlterarRepresentante/>}/>
                    <Route path="/alterar-forma-pagamento/:id" element={<AlterarFormaPagamento/>}/>
                    <Route path="/alterar-empresas" element={<AlterarEmpresas/>}/>
                {/* fim alteração */}
                
                {/* alertas */}
                    <Route path="/alerta-sucesso/:texto&:pagina" element={<AlertaSucesso/>}/>
                {/* fim alertas */}
                
            </Routes>
    )
}