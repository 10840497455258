import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Apis from "../../Apis";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import swal from "sweetalert";
const CadastrarFormaPagamento = () => {
    const [descricao, setDescricao] = useState('');
    const [parcelamento, setParcelamento] = useState();

    const navigate = useNavigate();
 
    const handleDescricao = (e) => {
        setDescricao(e.target.value);
    }
    const handleParcelamento = (e) => {
        setParcelamento(e.target.checked);
    }

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }
   
    const handleSubmit = () => {
        if(descricao !== ''){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
                body: JSON.stringify({"descricao": descricao, "parcelamento": parcelamento === true ? 1 : 0})
            };
            console.log(requestOptions)
            fetch(Apis.urlCadastrarFormaPagamento, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    localStorage.setItem("mensagem", result.retorno.mensagem);
                    if(result.retorno.sucesso){
                        navigate(`/alerta-sucesso/${result.retorno.mensagem}&listar-forma-pagamento`);
                    }
                    else{
                        alertaErro(result.retorno.mensagem);
                    }
                })
        }else{
            alertaErro('O campo descrição é obrigatório.');
        }
    }
    return(
        <>
            <div style={{padding: 15, paddingTop: 0}}>
                <div className="flex items-center w-full justify-center h-screen">
                    <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
                        <div className="flex flex-col items-center space-y-4">
                            <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">Cadastro de Forma de Pagamento</h1>
                            <p className="text-sm text-gray-500 text-center w-6/6">Informe os dados solicitados para realizar o cadastro da forma de pagamento.</p>
                            <label className="w-full">Descrição:
                                <input type="text" placeholder="Descrição" onChange={handleDescricao} value={descricao} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>
                            <label className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" st>
                                <FormControlLabel value={parcelamento} control={<Checkbox onChange={handleParcelamento} value={parcelamento}/>} label="Permitir Parcelamento no Pedido" labelPlacement="end"/>
                            </label>
                            <button onClick={handleSubmit} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Cadastrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CadastrarFormaPagamento;