import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { useNavigate } from 'react-router-dom';
import ButtonGroup from '@mui/material/ButtonGroup';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Excluir(props) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(!open)
  }
  const handleDelete = () => {
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
      body: JSON.stringify({'id': props.id})
    };
    fetch(props.url, requestOptions)
    .then(response => response.json())
    .then((result) => {
      navigate(`/alerta-sucesso/${result.retorno.mensagem}&${props.redirect}`);
      console.log(result)
    })
   
  }
  return (
    <>
      <DeleteIcon onClick={handleClose} size="small"/>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            Exclusão de {props.component}
          </Typography>
          <Typography id="modal-modal-description" className='text-center mt-2'>
            {props.texto}?
          </Typography>
          <div className='text-center mt-4'>
            <ButtonGroup disableElevation variant="contained" className='w-full'>
              <Button onClick={handleClose} color="success"  className='w-full'><DoNotDisturbAltIcon style={{marginRight: "5px"}}/>Cancelar</Button>
              <Button color="error" onClick={handleDelete} className='w-full'><DeleteIcon style={{marginRight: "5px"}}></DeleteIcon> Confirmar</Button>
            </ButtonGroup>
          </div>
        </Box>
      </Modal>
    </>
  );
}
