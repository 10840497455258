import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Loading from "../../loading/Loading";
import NenhumRegistro from "../../NenhumRegistro";
import styles from "./ListaProdutos.module.css";
import ButtonAdd from "../../cadastro/ButtonAdd";
import ViewListIcon from "@mui/icons-material/ViewList";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Excluir from "../../excluir/Excluir";
import { Link } from "react-router-dom";
import Apis from "../../Apis";
import Stack from "@mui/material/Stack";
const ListaProdutos = () => {
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pesquisar, setPesquisar] = useState("");
  const [registros, setRegistros] = useState(10);
  const [open, setOpen] = useState(false);
  const handlePesquisar = (e) => {
    setPesquisar(e.target.value);
  };

  const handleRegistros = (e) => {
    setRegistros(e.target.value);
  };
  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(""),
    };
    fetch(Apis.urlListarProdutos, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setClientes(result.registros);
        setLoading(false);
      });
  }, []);
  if (loading) {
    return <Loading />;
  } else {
    if (clientes) {
      return (
        <section className="bg-white py-10 lg:py-[80px]">
          <div className="container">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full px-4">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="w-full min-w-[160px] text-lg font-semibold text-gray-400 py-0 lg:py-0 border-l border-transparent"
                >
                  <ViewListIcon />
                  LISTA DE PRODUTOS
                </div>
                <div className={styles.cabecalho}>
                  <div>
                    <Tooltip title="Pesquisar por descrição">
                      <input
                        type="text"
                        placeholder="Pesquisar"
                        onChange={handlePesquisar}
                        value={pesquisar}
                        className="border-2 border-gray-300 rounded-lg w-full h-8 px-4"
                      />
                    </Tooltip>
                  </div>
                  <div>
                    <select
                      className="border-2 border-gray-300 rounded-lg w-full h-8 px-4"
                      onChange={handleRegistros}
                      value={registros}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
                <p className="mt-2 mb-2" style={{ fontSize: 11.5 }}>
                  registros: {clientes.length}
                </p>
                <div className="max-w-full overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead>
                      <tr className="bg-gray-600 text-center">
                        <th className="w-1/6 min-w-[360px] text-sm font-semibold text-white py-2 lg:py-1 px-1 lg:px-1 border-l border-gray-600">
                          PRODUTO
                        </th>
                        <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-2 lg:py-1 px-1 lg:px-1">
                          UNIDADE
                        </th>
                        <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-2 lg:py-1 px-1 lg:px-1">
                          PREÇO
                        </th>
                        <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-2 lg:py-1 px-1 lg:px-1">
                          ESTOQUE
                        </th>
                        <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-2 lg:py-1 px-1 lg:px-1 border-r border-gray-600">
                          AÇÕES
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientes
                        .filter((cliente) => {
                          if (pesquisar === "") {
                            return cliente;
                          }
                          if (
                            pesquisar !== "" &&
                            cliente.descricao
                              .toLowerCase()
                              .includes(pesquisar.toLowerCase())
                          ) {
                            return cliente;
                          }
                        })
                        .map(
                          (cliente, index) =>
                            index < registros && (
                              <tr
                                key={index}
                                className={`hover:text-blue-500 ${
                                  index % 2 == 0 && "bg-gray-100"
                                }`}
                              >
                                <td
                                  style={{ fontSize: "11.5px" }}
                                  className="uppercase text-center py-0 px-0 border-b border-l border-gray-300"
                                >
                                  {cliente.descricao}
                                </td>
                                <td
                                  style={{ fontSize: "11.5px" }}
                                  className="uppercase text-center py-0 px-0 border-b border-gray-300"
                                >
                                  {cliente.sigla}
                                </td>
                                <td
                                  style={{ fontSize: "11.5px" }}
                                  className="uppercase text-center py-0 px-0 border-b border-gray-300"
                                >
                                  R$ {cliente.preco}
                                </td>
                                <td
                                  style={{ fontSize: "11.5px" }}
                                  className="uppercase text-center py-0 px-0 border-b border-gray-300"
                                >
                                  {cliente.estoque}
                                </td>
                                <td className="text-center py-0 border-b border-r border-gray-300">
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    style={{ justifyContent: "center" }}
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      color="info"
                                      size="small"
                                    >
                                      <Link
                                        to={`/alterar-produto/${cliente.id}`}
                                      >
                                        <EditIcon />
                                      </Link>
                                    </IconButton>

                                    <IconButton
                                      aria-label="delete"
                                      color="error"
                                    >
                                      <Excluir
                                        redirect="listar-produtos"
                                        url={Apis.urlDeletarProdutos}
                                        id={cliente.id}
                                        component="Produto"
                                        texto={`Deseja Realmente excluir o produto ${cliente.descricao}`}
                                      />
                                    </IconButton>
                                  </Stack>
                                </td>
                              </tr>
                            )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {localStorage.getItem("usaMerchant") == 1 && (
            <ButtonAdd nome="produto" pagina="cadastrar-produtos" />
          )}
        </section>
      );
    } else {
      return <NenhumRegistro nome="produto" pagina="/cadastrar-produtos" />;
    }
  }
};
export default ListaProdutos;
