import React from "react";
import { Routes, Route } from "react-router-dom";
import CadastrarEmpresa from "../cadastro/empresa/cadastrar/CadastrarEmpresa";
import Login from "../login/Login";
import { AlertaSucesso } from '../modal/AlertaSucesso.js';
import AlterarSenha from "../alterar/senha/AlterarSenha";
import AlterarSenhaLink from "../alterar/senha/AlterarSenhaLink";
export default function RotasLogin(props){
    return(
            <Routes>
                <Route path="*" element={<Login setToken={props.setToken}/>}/>
                <Route path="/cadastrar-empresa" element={<CadastrarEmpresa/>}/>

                <Route path="/alterar-senha" element={<AlterarSenha/>}/>

                <Route path='/troca-senha/:key' element={<AlterarSenhaLink/>}/>

                {/* alertas */}
                <Route path="/alerta-sucesso/:texto&:pagina" element={<AlertaSucesso/>}/>
                {/* fim alertas */}
            </Routes>
    )
}