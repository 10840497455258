import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import EditIcon from '@mui/icons-material/Edit';
import Loading from "../../loading/Loading";
import styles from './ListaPedido.module.css';
import ViewListIcon from '@mui/icons-material/ViewList';
import Tooltip from '@mui/material/Tooltip';
import Excluir from "../../excluir/Excluir";
import ButtonAdd from "../../cadastro/ButtonAdd";
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import Apis from "../../Apis";

import Typography from '@mui/material/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const styleDataFiltro = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "400px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const ListaPedido = () => {
    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [pesquisar, setPesquisar] = useState('');
    const [requisicao, setRequisicao] = useState(false);
    const [openModalData, setOpenModalData] = useState(false);
    const handleRequisicao = () => {
        setRequisicao(!requisicao);
    }
    const handleOpenModalData = () => {
        setOpenModalData(true);
    }
    const handleOpenModalDataRequisicao = () => {
        setOpenModalData(false);
        handleRequisicao();
    }
    const handleCloseModalData = () => {
        setOpenModalData(false);
        handleRequisicao();
    }

    var data = new Date();
    var dia = String(data.getDate()).padStart(2, '0');
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();
    var dataAtual = ano + '-' + mes + '-' + dia;

    const [DataInicial, setDataInicial] = useState(localStorage.getItem('@adm_pedido_data_ini_pedido') ? localStorage.getItem('@adm_pedido_data_ini_pedido') : dataAtual);
    const [DataFinal, setDataFinal] = useState(localStorage.getItem('@adm_pedido_data_fim_pedido') ? localStorage.getItem('@adm_pedido_data_fim_pedido') : dataAtual);
    const [dataInicialLoc, setDataInicialLoc] = useState(localStorage.getItem('@adm_pedido_data_fim_pedido') ? localStorage.getItem('@adm_pedido_data_fim_pedido') : dataAtual);
    const [dataFinalLoc, setDataFinalLoc] = useState(localStorage.getItem('@adm_pedido_data_fim_pedido') ? localStorage.getItem('@adm_pedido_data_fim_pedido') : dataAtual);

    const [registros, setRegistros] = useState(10);
    const [produtos, setProdutos] = useState([]);

    const handleDataInicial = (e) => {
        setDataInicial(e.target.value);
        localStorage.setItem('@adm_pedido_data_ini_pedido', e.target.value)
    }

    const handleDataFinal = (e) => {
        setDataFinal(e.target.value);
        localStorage.setItem('@adm_pedido_data_fim_pedido', e.target.value)
    }

    const handlePesquisar = (e) => {
        setPesquisar(e.target.value);
    }

    const handleRegistros = (e) => {
        setRegistros(e.target.value);
    }

    const handleProdutos = (produtos) => {
        setProdutos(produtos.produtos)
        console.log(produtos)
        setOpenModal(true);
    }
    useEffect(() => {
        setDataInicialLoc(localStorage.getItem('@adm_pedido_data_ini_pedido') ? localStorage.getItem('@adm_pedido_data_ini_pedido') : DataInicial)
        setDataFinalLoc(localStorage.getItem('@adm_pedido_data_fim_pedido') ? localStorage.getItem('@adm_pedido_data_fim_pedido') : DataFinal)
    }, [DataInicial, DataFinal])

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` },
            body: JSON.stringify({ "data_inicial": dataInicialLoc, "data_final": dataFinalLoc })
        };
        fetch(Apis.urlListarPedidos, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setClientes(result.registros)
            })
    }, [dataFinalLoc, dataInicialLoc]);
    const handleCloseModal = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenModal(false);
    };
    if (loading) {
        return (
            <Loading />
        )
    }
    else {
        if (clientes.length > 0) {
            return (
                <section className="bg-white py-10 lg:py-[80px]">
                    <div className="container">
                        <div className="flex flex-wrap -mx-4">
                            <div className="w-full px-4">
                                <div style={{ display: 'flex', alignItems: 'center' }} className="w-full min-w-[160px] text-lg font-semibold text-gray-400 border-l border-transparent">
                                    <ViewListIcon />LISTA DE PEDIDOS
                                </div>
                                <div className={styles.cabecalho}>
                                    <div>
                                        <Tooltip title="Pesquisar por Cliente ou Número do Pedido">
                                            <input type="text" placeholder="Pesquisar" style={{ width: '150%' }} onChange={handlePesquisar} value={pesquisar} className="border-2 rounded-lg w-full h-8 px-4" />
                                        </Tooltip>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ fontSize: 11.5 }}>De: </span>
                                            <input type="date" placeholder="Pesquisar" onChange={handleDataInicial} style={{ fontSize: 11.5 }} value={DataInicial} className="border-2 ml-1 mr-2 rounded-lg h-8 px-4" />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ fontSize: 11.5 }}>até: </span>
                                            <input type="date" placeholder="Pesquisar" onChange={handleDataFinal} style={{ fontSize: 11.5 }} value={DataFinal} className="border-2 ml-2 rounded-lg h-8 px-4" />
                                        </div>
                                    </div>
                                    <div>
                                        <select className="border-2 rounded-lg h-8 px-4" style={{ fontSize: 11.5 }} onChange={handleRegistros} value={registros}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                                <Modal open={openModalData} onClose={handleCloseModalData} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                                    <Box sx={styleDataFiltro}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Informa a data inicial e final
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            <div>
                                                <input className="border-2 rounded-lg w-full h-10 px-4" type="date" onChange={handleDataInicial} value={DataInicial} />
                                            </div>
                                            <div>
                                                <input className="border-2 rounded-lg w-full mt-5 h-10 px-4" type="date" onChange={handleDataFinal} value={DataFinal} />
                                            </div>
                                            <div>
                                                <ButtonGroup variant="contained" aria-label="outlined primary button group" className="mt-5 w-full">
                                                    <Button onClick={handleOpenModalDataRequisicao} className="border-2 rounded-lg w-full h-10 px-4" size='small' color='success'>
                                                        Pesquisar
                                                    </Button>
                                                    <Button onClick={handleCloseModalData} className="border-2 rounded-lg w-full h-10 px-4" size='small' style={{ backgroundColor: '#ffc107' }}>
                                                        Fechar
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </Typography>
                                    </Box>
                                </Modal>
                                <p className="mt-2 mb-2" style={{ fontSize: 11.5 }}>registros: {clientes.length}</p>
                                <div className="max-w-full overflow-x-auto">
                                    <table className="table-auto w-full">
                                        <thead>
                                            <tr className="bg-gray-600 text-center">
                                                <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                    ID SERVIDOR
                                                </th>
                                                <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                    NÚMERO
                                                </th>
                                                <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l border-gray-600">
                                                    DATA
                                                </th>
                                                <th className="w-1/6 min-w-[360px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                    CLIENTE
                                                </th>
                                                <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                    VALOR
                                                </th>
                                                <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-r border-gray-600">
                                                    AÇÕES
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clientes.filter((cliente) => {
                                                if (pesquisar === '') {
                                                    return cliente
                                                }
                                                if (pesquisar !== '' && cliente.numero.toLowerCase().includes(pesquisar.toLowerCase())) {
                                                    return cliente
                                                }
                                                if (pesquisar !== '' && cliente.participante.nome.toLowerCase().includes(pesquisar.toLowerCase())) {
                                                    return cliente
                                                }
                                            }).map((cliente, index) => (
                                                index < registros &&
                                                <tr key={index} className={`hover:text-blue-500 ${index % 2 == 0 && 'bg-gray-100'}`}>
                                                    <td style={{ fontSize: "11.5px" }} className="text-center border-b border-l border-gray-300">
                                                        {cliente.id}
                                                    </td>
                                                    <td style={{ fontSize: "11.5px" }} className="text-center border-b border-l border-gray-300">
                                                        {cliente.numero}
                                                    </td>
                                                    <td style={{ fontSize: "11.5px" }} className="text-center border-b border-gray-300">
                                                        {cliente.data.split('-').reverse().join('/')}
                                                    </td>
                                                    <td style={{ fontSize: "11.5px" }} className="text-center uppercase border-b">
                                                        {cliente.participante.nome}
                                                    </td>
                                                    <td style={{ fontSize: "11.5px" }} className="text-center border-b border-gray-300">
                                                        R$ {(parseFloat(cliente.valor) - parseFloat(cliente.desconto)).toFixed(2)}
                                                    </td>
                                                    <td className="text-center py-0 border-b border-r border-gray-300">
                                                        <Stack direction="row" spacing={1} style={{ justifyContent: 'center' }}>
                                                            <IconButton aria-label="delete" color="success" size="small" onClick={() => handleProdutos(cliente)}>
                                                                <ProductionQuantityLimitsIcon />
                                                            </IconButton>
                                                            <IconButton aria-label="delete" color="info" size="small">
                                                                <Link to={`/alterar-pedido/${cliente.id}`}>
                                                                    <EditIcon />
                                                                </Link>
                                                            </IconButton>
                                                            <IconButton aria-label="delete" color="error">
                                                                <Excluir redirect="listar-pedidos" url={Apis.urlDeletarPedidoVenda} id={cliente.id} component="Pedido" texto={`Deseja Realmente excluir o pedido ${cliente.numero}`} />
                                                            </IconButton>
                                                        </Stack>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal keepMounted open={openModal} onClose={handleCloseModal} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
                        <Box sx={style}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} className="w-full min-w-[160px] mb-3 text-lg font-semibold text-gray-400 py-0 lg:py-0 px-3 lg:px-0 border-l border-transparent">
                                <ViewListIcon />Itens do Pedido
                            </div>
                            <div className="max-w-full overflow-x-auto">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className="bg-gray-600 text-center">
                                            <th className="w-1/6 min-w-[360px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l border-gray-600">
                                                DESCRIÇÃO
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                CÓDIGO
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                UN
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                QUANTIDADE
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                PREÇO
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                DESCONTO
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                TOTAL
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {produtos.map((cliente, index) => (
                                            <tr key={index} className={`hover:text-blue-500 ${index % 2 == 0 && 'bg-gray-100'}`}>
                                                <td style={{ fontSize: "11.5px" }} className="text-center uppercase border-b border-l border-gray-300">
                                                    {cliente.descricao}
                                                </td>
                                                <td style={{ fontSize: "11.5px" }} className="text-center border-b border-gray-300">
                                                    {cliente.codpro}
                                                </td>
                                                <td style={{ fontSize: "11.5px" }} className="text-center uppercase border-b border-gray-300">
                                                    {cliente.unidade ? cliente.unidade : "Não Informada"}
                                                </td>
                                                <td style={{ fontSize: "11.5px" }} className="text-center uppercase py-2 border-b border-gray-300">
                                                    {cliente.qt} {cliente.unidade}
                                                </td>
                                                <td style={{ fontSize: "11.5px" }} className="text-center border-b border-gray-300">
                                                    R$ {cliente.preco}
                                                </td>
                                                <td style={{ fontSize: "11.5px" }} className="text-center border-b border-gray-300">
                                                    R$ {cliente.desconto}
                                                </td>
                                                <td style={{ fontSize: "11.5px" }} className="text-center border-b border-r border-gray-300">
                                                    R$ {cliente.total}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Box>
                    </Modal>
                    <ButtonAdd nome='pedido' pagina='cadastrar-pedido' />
                </section>
            )
        }
        else {
            return (
                <>
                    <div>
                        <Box sx={styleDataFiltro}>
                            <Typography id="modal-modal-title" className="text-center" variant="h6" component="h2">
                                Nenhum registro foi encontrado, Informa a data inicial e final
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <div>
                                    <input className="border-2 rounded-lg w-full h-10 px-4" type="date" onChange={handleDataInicial} value={DataInicial} />
                                </div>
                                <div>
                                    <input className="border-2 rounded-lg w-full mt-5 h-10 px-4" type="date" onChange={handleDataFinal} value={DataFinal} />
                                </div>
                            </Typography>
                        </Box>
                    </div>
                    <ButtonAdd nome='pedido' pagina='cadastrar-pedido' />
                </>
            )
        }
    }
}
export default ListaPedido;