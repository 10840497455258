import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Snackbar, Tooltip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Apis from "../../Apis";
import Loading from "../../loading/Loading";
import Stack from '@mui/material/Stack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Alert from '@mui/material/Alert';
import styles from './CadastrarPedido.module.css'
import swal from "sweetalert";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const styleData = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const styleModalDelete = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
export default function CadastrarPedido() {
    const [openModalProdutos, setOpenModalProdutos] = useState(false);
    const [openModalClientes, setOpenModalClientes] = useState(false);
    const [produtosPedidoTemp, setProdutosPedidoTemp] = useState([]);
    const [produtosPedido, setProdutosPedido] = useState([]);
    const [descricaoProd, setDescricaoProd] = useState('');
    const [unidadeProd, setUnidadeProd] = useState('');
    const [precoProd, setPrecoProd] = useState('');
    const [quantidadeProd, setQuantidadeProd] = useState('');
    const [descontoProd, setDescontoProd] = useState('');
    const [descontoTotal, setDescontoTotal] = useState(0);
    const [valorTotal, setValorTotal] = useState(0);
    const [liquido, setLiquido] = useState(0);
    const [loadingProd, setLoadingProd] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [representantes, setRepresentantes] = useState([]);
    const [idRepresentante, setIdRepresentante] = useState('');
    const [pesquisar, setPesquisar] = useState('');
    const [cliente, setCliente] = useState('');
    const [clienteId, setClienteId] = useState('');
    const [descricaoDeleteProd, setDescricaoDeleteProd] = useState(false);
    const [idDeleteProd, setIdDeleteProd] = useState(false);
    const [openDeleteProd, setOpenDeleteProd] = useState(false);
    const [codigoProd, setCodigoProd] = useState('');
    const [qtdParcelas, setQtdParcelas] = useState('');
    const [pagamento, setPagamento] = useState(0);
    const [valorParcelas, setValorParcelas] = useState(0);
    const [parcelas, setParcelas] = useState([]);
    const [parcelaDataAlt, setParcelaDataAlt] = useState();
    const [dataAtualParcela, setDataAtualParcela] = useState();
    
    const [inserir, setInserir] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    var data = new Date();
    var dia = String(data.getDate()).padStart(2, '0');
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();
    var dataAtual = ano + '-' + mes + '-' + dia;
    const [dataPedido, setDataPedido] = useState(dataAtual);
    const [dataSaidaPedido, setDataSaidaPedido] = useState(dataAtual);


    
    const [openModalDataParcela, setOpenModalDataParcela] = useState(false);
    const handleOpenDataParcela = (parcela) => {
        setParcelaDataAlt(parcela);
        setDataAtualParcela(parcela.vencimento);
        setOpenModalDataParcela(true);
    }
    const handleCloseDataParcela = () => setOpenModalDataParcela(false);

    const handleAltDataParcela = () => {
        var meuObjeto = parcelas;
        for(var i = 0; i < parcelas.length; i++){
            if(meuObjeto[i].parcela == parcelaDataAlt.parcela){
                meuObjeto[i].vencimento = dataAtualParcela;

            }
        }
        setParcelas(meuObjeto);
        handleCloseDataParcela();
    }

    const handleDataAtualParcela = (e) => {
        setDataAtualParcela(e.target.value)
    }

    const handleOpenDeleteProd = (id, descricao) => {
        setDescricaoDeleteProd(descricao);
        setIdDeleteProd(id);
        setOpenDeleteProd(true);
    }
    const handleIdRepresentante = (e) => {
        setIdRepresentante(e.target.value);
    }
    const handleGerarParcelas = () => {
        if(valorTotal <= 0){
            alertaErro("Não é possivel gerar parcelas pois o valor do pedido é menor que zero!")
        }
        else{
            setValorParcelas(valorTotal / qtdParcelas);
            var parcelaTemp = [];

            var data2 = new Date();
            var dia2 = "";
            var mes2 = "";
            var ano2 = "";
            
            for(var i = 0; i < qtdParcelas; i++){
                data2.setDate(data2.getDate() + 30);
                var dia2 = String(data2.getDate()).padStart(2, '0');
                var mes2 = String(data2.getMonth() + 1).padStart(2, '0');
                var ano2 = data2.getFullYear();

                parcelaTemp = [...parcelaTemp, {"parcela": i + 1, "vencimento": `${ano2}-${mes2}-${dia2}`, "valor": valorTotal / qtdParcelas}];
            }
            setParcelas(parcelaTemp)
        }
    }
    const handlePagamento = (e) => {
        setPagamento(e.target.value);
    }
    const handleQtdParcelas = (e) => {
        setQtdParcelas(e.target.value);
    }
    const handleCloseDeleteProd = () => setOpenDeleteProd(false);
    const delProduto = (id) => {
        setParcelas([]);
        for(var i = 0; i < produtosPedido.length; i++){
            if(produtosPedido[i].id === id){
                setDescontoTotal((parseFloat(descontoTotal) - parseFloat(produtosPedido[i].desconto)).toFixed(2))
                setLiquido((parseFloat(liquido) - (parseFloat(produtosPedido[i].total) + parseFloat(produtosPedido[i].desconto))).toFixed(2))
                setValorTotal((parseFloat(valorTotal) - parseFloat(produtosPedido[i].total)).toFixed(2))
            }
        }
        setProdutosPedido(produtosPedido.filter((produtosPedido) => produtosPedido.id !== id))
        setOpenDeleteProd(false);
    }

    const handleAddProd = () => {
        if(quantidadeProd <= 0 || precoProd <= 0 || produtosPedidoTemp.length <= 0){
            if(produtosPedidoTemp.length <= 0){
                alertaErro("Insira um produto para continuar!")
                return
            }
            if(quantidadeProd <= 0){
                alertaErro("A quantidade do produto deve ser maior que zero!")
            }
            if(precoProd <= 0){
                alertaErro("O preço do produto deve ser maior que zero!")
            }
        }else{
            setParcelas([]);
            produtosPedido.filter((produtosPedido) => { if(produtosPedido.id === produtosPedidoTemp[0].id){
                setInserir(false)
            } })
            produtosPedido.filter((produtosPedido) => { if(produtosPedido.id !== produtosPedidoTemp[0].id){
                setInserir(true)
            } })
                setLiquido(((parseFloat(precoProd) * parseFloat(quantidadeProd)) + parseFloat(liquido)).toFixed(2));
                setDescontoTotal((parseFloat(descontoProd > 0 ? descontoProd : 0) + parseFloat(descontoTotal)).toFixed(2));
                setValorTotal((((parseFloat(precoProd) * parseFloat(quantidadeProd)) - parseFloat(descontoProd > 0 ? descontoProd : 0)) + parseFloat(valorTotal)).toFixed(2));
                setProdutosPedido([...produtosPedido, {"id": uuidv4(), "descricao": produtosPedidoTemp[0].descricao, "codpro": produtosPedidoTemp[0].codpro,  "produto_id": produtosPedidoTemp[0].produto_id, "unidade_id": produtosPedidoTemp[0].unidade_id, "unidade": produtosPedidoTemp[0].unidade, "qt": quantidadeProd, "preco": precoProd, "total": (quantidadeProd * precoProd), "desconto": descontoProd > 0 ? descontoProd : 0}])
                setQuantidadeProd('')
                setDescontoProd('')
                setCodigoProd('');
                setDescricaoProd('');
                setUnidadeProd('');
                setPrecoProd('');
        }
    }
    const handleOpenModalProdutos = () => {
         setOpenModalProdutos(true);
    }
    const handleCloseProdutos = () => setOpenModalProdutos(false);

    const handleOpenModalClientes = () => {
        setOpenModalClientes(true);
   }
   const handleCloseClientes = () => setOpenModalClientes(false);
    
    const handlePreco = (e) => {
        setPrecoProd(e.target.value);
    }
    const handleDesconto = (e) => {
        setDescontoProd(e.target.value);
    }
    const handleQtd = (e) => {
        setQuantidadeProd(e.target.value);
    }
    const handleDataPedido = (e) => {
        setDataPedido(e.target.value);
    }
    const handleDataSaidaPedido = (e) => {
        setDataSaidaPedido(e.target.value);
    }
    const handleProId = (id) => {
        setLoadingProd(false)
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify({"id": id})
        };
        fetch(Apis.urlListarOneProdutos, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setDescricaoProd(result.registros[0].descricao);
                setCodigoProd(result.registros[0].codigo);
                setUnidadeProd(result.registros[0].unidade)
                setPrecoProd(result.registros[0].preco)
                setProdutosPedidoTemp([{"descricao": result.registros[0].descricao, "codpro": result.registros[0].codigo,  "produto_id": result.registros[0].id, "unidade_id": result.registros[0].unidade_id, "unidade": result.registros[0].unidade}])
                setLoadingProd(true)
            })
            handleCloseProdutos();
    }

    const handleClienteId = (id) => {
        setLoadingProd(false)
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify({"id": id})
        };
        fetch(Apis.urlListarOneClientes, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setCliente(result.registros[0].nome)
                setClienteId(result.registros[0].id)
            })
            handleCloseClientes();
    }

    const handlePesquisar = (e) => {
        setPesquisar(e.target.value);
    }

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify("")
        };
        fetch(Apis.urlListarProdutos, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setProdutos(result.registros)
            })
    }, []);
    const [formaPagamento, setFormaPagamento] = useState([]);
    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify({})
        };
        fetch('https://gesuportelogico.com.br/api/formaspagamento/read', requestOptions)
            .then(response => response.json())
            .then((result) => {
                setFormaPagamento(result.registros)
            })
    }, []);
    useEffect(() => {
        setLoadingProd(true);
        setLoading(true)
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify("")
        };
        fetch(Apis.urlListarClientes, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if(result.registros !== false){
                    setClientes(result.registros)
                }
                setLoading(false)
            })
    }, []);
    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify("")
        };
        fetch(Apis.urlListarRepresentantes, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setRepresentantes(result.registros)
            })
    }, []);
    const handleGravar = () => {
        if(clienteId === '' || valorTotal <= 0){
            if(clienteId === ''){
                alertaErro("Selecione um cliente para continuar!")
                return
            }
            if(valorTotal <= 0){
                alertaErro("O valor do pedido deve ser maior que zero!")
            }
        }
        else{
            setLoading(true)
            const data = {"data": dataPedido, "data_saida": dataSaidaPedido, "forma_pagamento_id": pagamento, "natureza_id": 0, "participante_id": clienteId, "vendedor_id": idRepresentante > 0 ? idRepresentante : 0, "valor": liquido, "desconto": descontoTotal, "produtos": produtosPedido, "parcelas": parcelas}
            console.log(data)
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
                body: JSON.stringify(data)
            };
            fetch(Apis.urlCadastrarPedidoVenda, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    console.log(result)
                    if(result.retorno.sucesso){
                        navigate(`/alerta-sucesso/${result.retorno.mensagem}&listar-pedidos`);
                    }else{
                        alertaErro(result.retorno.mensagem);
                    }
                    setLoading(false)
                })
        }
    }
    if(loading){
        return(
            <Loading/>
        )
    }
    if(clientes.length <= 0 && !loading || produtos === undefined){
        return(
            <div>
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign: 'center'}}>
                        Olá {localStorage.getItem('nome')}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: 'center'}}>
                        Para criar um novo pedido é necessário possuir dados de clientes e produtos, verifique os dados e tente novamente.
                    </Typography>
                </Box>
            </div>
        )
    }
    if(!loading && clientes.length > 0 && produtos !== undefined){
            return (
                <>
                    <Modal open={openDeleteProd} onClose={handleCloseDeleteProd} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={styleModalDelete}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Remover produto
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Deseja remover o produto <strong>{descricaoDeleteProd}</strong>?
                            </Typography>
                            <div className='text-center mt-4'>
                                <ButtonGroup disableElevation variant="contained" className='w-full'>
                                    <Button onClick={handleCloseDeleteProd} color="success" className='w-full'><DoNotDisturbAltIcon style={{marginRight: "5px"}}/>Cancelar</Button>
                                    <Button color="error" className='w-full' onClick={() => delProduto(idDeleteProd)}><DeleteIcon style={{marginRight: "5px"}}></DeleteIcon> Confirmar</Button>
                                </ButtonGroup>
                            </div>
                        </Box>
                    </Modal>

                    <Modal keepMounted open={openModalDataParcela} onClose={handleCloseDataParcela} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
                        <Box sx={styleData}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Alterar Data da Parcela
                                </Typography>
                                <div className="items-center w-full justify-center h-screen h-full mt-5">
                                    <label className="w-full">Data:
                                        <input type="date" onChange={handleDataAtualParcela} value={dataAtualParcela} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                                    </label>
                                    <button onClick={handleAltDataParcela} className="bg-green-700 text-white rounded-md font-semibold px-4 py-3 w-full mt-5">Alterar</button>
                                </div>
                        </Box>
                    </Modal>

                    {/* modal produtos */}
                    <Modal keepMounted open={openModalProdutos} onClose={handleCloseProdutos} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
                        <Box sx={style}>
                            <a className="mb-3" id="keep-mounted-modal-title" variant="h6" component="h2">
                                {produtos.length} Produtos disponiveis
                            </a>
                            <div className="mb-3 mt-3">
                                <input type="text" placeholder="Pesquisar por descrição ou codigo" onChange={handlePesquisar} value={pesquisar}  className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </div>
                            <a>Clique sobre produto desejado para seleciona-lo</a>
                            <div className="max-w-full mt-3 overflow-x-auto">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className="bg-gray-600 text-center">
                                            <th className="w-1/6 min-w-[360px] py-1 text-sm font-sm text-white px-1 lg:px-1 border-l border-gray-600">
                                                DESCRIÇÃO
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-sm text-white px-1 lg:px-1">
                                                PREÇO
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-sm text-white px-1 lg:px-1">
                                                ESTOQUE
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-sm text-white px-1 lg:px-1 border-r border-gray-600">
                                                UNIDADE
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {produtos.filter((produto) => {
                                            if(pesquisar === ''){
                                                return produto
                                            }
                                            if(pesquisar !== '' && produto.descricao.toLowerCase().includes(pesquisar.toLowerCase())){
                                                return produto
                                            }
                                            if(pesquisar !== '' && produto.codigo.toLowerCase().includes(pesquisar.toLowerCase())){
                                                return produto
                                            }
                                            }).map((produto, index) =>
                                                index < 10 &&
                                                <tr onClick={() => handleProId(produto.id)} className={`cursor-pointer hover:text-blue-500 ${index % 2 == 0 && 'bg-gray-100'}`}>
                                                    <td className="text-center p-1 text-dark font-sm text-base border-b border-l border-gray-300" style={{fontSize: 11.5}}>
                                                        {produto.descricao}
                                                    </td>
                                                    <td className="text-center text-dark font-sm text-base border-b border-gray-300" style={{fontSize: 11.5}}>
                                                        R$ {produto.preco}
                                                    </td>
                                                    <td className="text-center text-dark font-sm text-base border-b border-gray-300" style={{fontSize: 11.5}}>
                                                        {produto.estoque}
                                                    </td>
                                                    <td className="text-center text-dark font-sm text-base border-b border-r border-gray-300" style={{fontSize: 11.5}}>
                                                        {produto.sigla}
                                                    </td>
                                                </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <button onClick={handleCloseProdutos} className="bg-blue-400 mt-3 text-white rounded-md font-semibold px-4 py-2 w-full" data-bs-dismiss="modal">Fechar</button>
                        </Box>
                    </Modal>

                    {/* modal clientes */}
                    <Modal keepMounted open={openModalClientes} onClose={handleCloseClientes} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
                        <Box sx={style}>
                            <a className="mb-3" id="keep-mounted-modal-title" variant="h6" component="h2">
                                {clientes.length} Clientes disponiveis
                            </a>
                            <div className="mb-3 mt-3">
                                <input type="text" placeholder="Pesquisar por razão, CNPJ ou CPF" onChange={handlePesquisar} value={pesquisar}  className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </div>
                            <a>Clique sobre cliente desejado para seleciona-lo</a>
                            <div className="max-w-full mt-3 overflow-x-auto">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className="bg-gray-600 text-center">
                                            <th className="w-1/6 min-w-[360px] py-1 text-sm font-sm text-white px-1 lg:px-1 border-l border-gray-600">
                                                CLIENTE
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-sm text-white px-1 lg:px-1">
                                                CNPJ/CPF
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-sm text-white px-1 lg:px-1">
                                                UF
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clientes.filter((cliente) => {
                                            if(pesquisar === ''){
                                                return cliente
                                            }
                                            if(pesquisar !== '' && cliente.nome.toLowerCase().includes(pesquisar.toLowerCase())){
                                                return cliente
                                            }
                                            if(pesquisar !== '' && cliente.cnpj_cpf.toLowerCase().includes(pesquisar.toLowerCase())){
                                                return cliente
                                            }
                                            }).map((cliente, index) =>
                                                index < 10 &&
                                                <tr onClick={() => handleClienteId(cliente.id)} className={`cursor-pointer hover:text-blue-500 ${index % 2 == 0 && 'bg-gray-100'}`}>
                                                    <td className="text-center uppercase p-1 text-dark font-sm text-base border-b border-l border-gray-300" style={{fontSize: 11.5}}>
                                                        {cliente.nome}
                                                    </td>
                                                    <td className="text-center text-dark font-sm text-base border-b border-gray-300" style={{fontSize: 11.5}}>
                                                        {cliente.cnpj_cpf}
                                                    </td>
                                                    <td className="text-center text-dark font-sm text-base border-b border-r border-gray-300" style={{fontSize: 11.5}}>
                                                        {cliente.uf}
                                                    </td>
                                                </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <button onClick={handleCloseClientes} className="bg-blue-400 mt-3 text-white rounded-md font-semibold px-4 py-2 w-full" data-bs-dismiss="modal">Fechar</button>
                        </Box>
                    </Modal>

                    <section className="bg-white py-10 lg:py-[80px]">
                        <div className="container">
                            <div className="flex flex-wrap -mx-4">
                                <div className="w-full px-4">
                                    <div className={styles.cabecalho}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <div style={{display: 'flex', alignItems: 'center'}} className="w-full min-w-[160px] text-lg font-semibold text-gray-400 py-0 lg:py-0 px-3 lg:px-4 border-l border-transparent">
                                                Pedido de Venda
                                            </div>
                                        </div>
                                        <div>
                                            <Stack spacing={2} direction="row">
                                                <Button onClick={handleGravar} variant="contained">GRAVAR</Button>
                                            </Stack>
                                        </div>
                                    </div>
                                    <div className="max-w-full overflow-x-auto">
                                        <table className="table-auto w-full">
                                            <tbody>
                                                    <tr>
                                                        <td style={{fontSize: "11.5px"}} className="text-base py-2 px-2 border-b border-l border-t border-gray-300">
                                                            <label className="w-full">Cliente:
                                                                {/* {`${clienteId} - ${cliente}`} */}
                                                                <input type="text" style={{cursor: 'pointer'}} placeholder="Selecionar o cliente" onClick={handleOpenModalClientes} value={cliente} className="border-2 border-gray-300 rounded-lg w-full h-8 px-4"/>
                                                            </label>
                                                        </td>
                                                        <td style={{fontSize: "11.5px"}} className="text-base py-2 px-2 bg-white border-b border-t border-gray-300">
                                                            <label className="w-full">Vendedor:
                                                                {representantes ? 
                                                                    <select className="border-2 border-gray-300 rounded-lg w-full h-8 px-4" onChange={handleIdRepresentante} value={idRepresentante}>
                                                                        <option disabled>- selecionar vendedor -</option>
                                                                        <option value='0'>Sem vendedor</option>
                                                                        {representantes.map((representante) => (
                                                                            <option value={representante.id} key={representante.id}>{representante.nome}</option>
                                                                        ))}
                                                                    </select>
                                                                    :
                                                                    <Link to="/cadastrar-representante/1" className="bg-blue-400 text-center text-white rounded-md font-semibold px-4 py-3 w-full">Adicionar vendedor</Link>
                                                                }
                                                            </label>
                                                        </td>
                                                        <td style={{fontSize: "11.5px"}} className="text-base py-2 px-2 bg-white border-b border-t border-gray-300">
                                                            <label>Data:
                                                                <input type="date" onChange={handleDataPedido} value={dataPedido} className="border-2 border-gray-300 rounded-lg w-full h-8 px-4"/>
                                                            </label>
                                                        </td>
                                                        <td style={{fontSize: "11.5px"}} className="text-base py-2 px-2 bg-white border-b border-t border-gray-300">
                                                            <label>Saída:
                                                                <input type="date" onChange={handleDataSaidaPedido} value={dataSaidaPedido} className="border-2 border-gray-300 rounded-lg w-full h-8 px-4"/>
                                                            </label>
                                                        </td>
                                                        <td style={{fontSize: "11.5px", textAlign: 'left'}} className="text-base py-2 px-2 bg-white border-b border-t border-r border-gray-300">
                                                            <label className="w-full">Tabela de Preços:
                                                                <select className="border-2 border-gray-300 rounded-lg w-full h-8 px-4">
                                                                    <option selected>Selecione uma tabela</option>
                                                                </select>
                                                            </label>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{fontSize: "11.5px"}} className="text-base py-2 px-2 border-b border-l border-gray-300">
                                                            
                                                        </td>
                                                        <td style={{fontSize: "11.5px"}} className="text-base py-2 px-2 bg-white border-b border-gray-300">
                                                            
                                                        </td>
                                                        <td style={{fontSize: "11.5px"}} className="text-base py-2 px-2 bg-white border-b border-gray-300">
                                                            
                                                        </td>
                                                        <td style={{fontSize: "11.5px"}} className="text-base py-2 px-2 bg-white border-b border-gray-300">
                                                            <label className="w-full">Desconto:
                                                                <input type="text" disabled className="border-2 border-gray-300 rounded-lg w-full h-8 px-4" value={`R$ ${descontoTotal}`}/>
                                                            </label>
                                                        </td>
                                                        <td style={{fontSize: "11.5px", textAlign: 'left'}} className="text-base py-2 px-2 bg-white border-b border-r border-gray-300">
                                                            <label className="w-full">Total do Pedido:
                                                                <input type="text" disabled className="border-2 border-gray-300 rounded-lg w-full h-8 px-4" value={`R$ ${valorTotal}`}/>
                                                            </label>
                                                        </td>
                                                    </tr>
                                            </tbody>
                                        </table>
                                        <div className={styles.cabecalho} style={{marginTop: 40}}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{display: 'flex', alignItems: 'center'}} className="w-full min-w-[160px] text-lg font-semibold text-gray-400 py-0 lg:py-0 px-3 lg:px-4 border-l border-transparent">
                                                    Itens do Pedido
                                                </div>
                                            </div>
                                        </div>
                                        <div className="max-w-full overflow-x-auto">
                                            <table className="table-auto w-full">
                                                <tbody>
                                                        <tr>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-xs  text-base py-2 px-2 border-b border-l border-t border-r border-gray-300`}>
                                                                <div>
                                                                    <p style={{color: 'gray', cursor: 'pointer'}} onClick={handleOpenModalProdutos}>{codigoProd !== '' ? codigoProd : 'Código'}</p>
                                                                </div>
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-left font-xs capitalize text-base py-2 px-2 border-l border-t border-b border-gray-300`}>
                                                                <div style={{display: 'flex'}}>
                                                                    <input type="text" onClick={handleOpenModalProdutos} style={{paddingLeft: 5, width: '100%', outline: 0, cursor: 'pointer'}} placeholder="Descrição" value={descricaoProd}/>
                                                                    <MoreVertIcon style={{cursor: 'pointer'}} onClick={handleOpenModalProdutos}/>
                                                                </div>
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-xs  text-base py-2 px-2 border-b border-l border-t border-r border-gray-300`}>
                                                                <p style={{color: 'gray'}}>{unidadeProd !== '' ? unidadeProd : 'Unidade'}</p>
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-xs  text-base py-2 px-2 border-b border-l border-t border-r border-gray-300`}>
                                                                <Tooltip title="Quantidade">
                                                                    <input type="number" style={{paddingLeft: 5, width: '100%', outline: 0}} placeholder="Quantidade" onChange={handleQtd} value={quantidadeProd}/>
                                                                </Tooltip>
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-xs  text-base py-2 px-2 border-b border-l border-t border-r border-gray-300`}>
                                                                <Tooltip title="Preço">
                                                                    <input type="number" style={{paddingLeft: 5, width: '100%', outline: 0}} placeholder="Preço" onChange={handlePreco} value={parseFloat(precoProd)}/>
                                                                </Tooltip>
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-xs text-base py-2 px-2 border-b border-r border-t border-gray-300`}>
                                                                <Tooltip title="Desconto">
                                                                    <input type="number" style={{paddingLeft: 5, width: '100%', outline: 0}} placeholder="Desconto" onChange={handleDesconto} value={descontoProd}/>
                                                                </Tooltip>
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-xs  text-base py-2 px-2 border-b border-l border-t border-r border-gray-300`}>
                                                                <Tooltip title="Total do item">
                                                                    <p>{(quantidadeProd * precoProd) - descontoProd}</p>
                                                                </Tooltip>
                                                            </td>
                                                            <td className={`text-center font-xs text-base py-2 px-2 border-b border-r border-t border-gray-300`}>
                                                            <Stack spacing={2}>
                                                                <Button variant="contained"style={{textTransform: 'lowercase'}} onClick={handleAddProd}>Incluir</Button>
                                                            </Stack>
                                                            </td>
                                                        </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {produtosPedido.length > 0 ?
                                            <div className="max-w-full overflow-x-auto mt-3">
                                                <table className="table-auto w-full">
                                                    <thead>
                                                        <tr className="bg-gray-600 text-center">
                                                            <th className="w-1/1 min-w-[80px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l border-gray-300">
                                                                Código
                                                            </th>
                                                            <th className="w-1/6 min-w-[360px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l border-gray-300">
                                                                Descrição
                                                            </th>
                                                            <th className="w-1/1 min-w-[80px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l">
                                                                Unid
                                                            </th>
                                                            <th className="w-1/1 min-w-[80px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l">
                                                                Qtdade
                                                            </th> 
                                                            <th className="w-1/1 min-w-[80px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l border-gray-300">
                                                                Preço
                                                            </th>
                                                            <th className="w-1/1 min-w-[80px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l">
                                                                Desconto
                                                            </th>
                                                            <th className="w-1/1 min-w-[80px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l">
                                                                Total
                                                            </th>   
                                                            <th className="w-1/1 min-w-[80px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l border-r  border-gray-300">
                                                                Ação
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {produtosPedido.map((produtoPedido, index) => (
                                                        <tr className="hover:text-blue-500">
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-sm capitalize text-base border-l border-b border-gray-300`}>
                                                                {produtoPedido.codpro}
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-sm  text-base border-b border-l border-r border-gray-300`}>
                                                                {produtoPedido.descricao}
                                                                
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-sm  text-base border-b border-l border-r border-gray-300`}>
                                                                {produtoPedido.unidade}
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-sm text-base border-b border-r border-gray-300`}>
                                                                {produtoPedido.qt}
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-sm text-base border-b border-r border-gray-300`}>
                                                                {`R$ ${parseFloat(produtoPedido.preco).toFixed(2)}`}
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-sm text-base border-b border-r border-gray-300`}>
                                                                {`R$ ${parseFloat(produtoPedido.desconto).toFixed(2)}`}
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-sm  text-base border-b border-l border-r border-gray-300`}>
                                                                {`R$ ${parseFloat(produtoPedido.total).toFixed(2)}`}
                                                            </td>
                                                            <td style={{fontSize: "11.5px"}} className={`text-center font-sm text-base border-b border-r border-gray-300`}>
                                                                <DeleteIcon color='error' onClick={() => handleOpenDeleteProd(produtoPedido.id, produtoPedido.descricao)} style={{fontSize: '20px', cursor: 'pointer'}}/>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                        {produtosPedido.length > 0 && formaPagamento ?
                                            <>
                                                <div className={styles.cabecalho} style={{marginTop: 40}}>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <div style={{display: 'flex', alignItems: 'center'}} className="w-full min-w-[160px] text-lg font-semibold text-gray-400 py-0 lg:py-0 px-3 lg:px-4 border-l border-transparent">
                                                            Pagamento
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="max-w-full overflow-x-auto mt-2">
                                                    <table className="table-auto w-full">
                                                        <tbody>
                                                                <tr>
                                                                    <td style={{fontSize: "11.5px"}} className={`text-center font-xs  text-base py-2 px-2 border-b border-l border-t border-gray-300`}>
                                                                        {/* <input type="number" style={{paddingLeft: 5, width: '100%', outline: 0}} placeholder="Quantidade" onChange={handleQtd} value={quantidadeProd}/> */}
                                                                        {formaPagamento &&
                                                                            <select className="w-full h-8 px-4" style={{paddingLeft: 5, width: '100%', outline: 0}} value={pagamento} onChange={handlePagamento}>
                                                                                <option value='0'>Condições de Pagamento</option>
                                                                                {formaPagamento.map((pagamento) => (
                                                                                    <option value={pagamento.id} key={pagamento.id}>{pagamento.descricao}</option>
                                                                                ))}
                                                                            </select>
                                                                        }
                                                                    </td>
                                                                    <td style={{fontSize: "11.5px"}} className={`text-center font-xs  text-base py-2 px-2 border-b border-r border-l border-t border-gray-300`}>
                                                                        <Tooltip title="Quantidade de parcelas">
                                                                            <input type="number" style={{paddingLeft: 5, width: '100%', outline: 0}} placeholder="Quantidade de parcelas" value={qtdParcelas} onChange={handleQtdParcelas}/>
                                                                        </Tooltip>
                                                                    </td>
                                                                    <td className={`text-center font-xs text-base py-2 px-2 border-b border-t border-r border-gray-300`}>
                                                                    <Stack spacing={2}>
                                                                        <Button variant="contained"style={{textTransform: 'lowercase'}} onClick={handleGerarParcelas}>Gerar parcelas</Button>
                                                                    </Stack>
                                                                    </td>
                                                                </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                            :
                                            <div></div>
                                        }
                                        {parcelas.length > 0 && produtosPedido.length > 0 ?
                                            <div className="max-w-full overflow-x-auto mt-10">
                                                <table className="table-auto">
                                                        <thead>
                                                            <tr className="bg-gray-600 text-center">
                                                                <th className="w-1/6 min-w-[100px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l border-gray-300">
                                                                    Parcela
                                                                </th> 
                                                                <th className="w-1/6 min-w-[100px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l border-gray-300">
                                                                    Data
                                                                </th> 
                                                                <th className="w-1/6 min-w-[100px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l border-r  border-gray-300">
                                                                    Valor
                                                                </th>
                                                                {/* <th className="w-1/6 min-w-[100px] text-sm font-semibold text-white py-2 lg:py-2 px-3 lg:px-4 border-l border-r  border-gray-300">
                                                                    Ação
                                                                </th> */}
                                                            </tr>
                                                        </thead>
                                                    <tbody>
                                                        {parcelas.map((parcela, index) => (
                                                            <tr>
                                                                <td style={{fontSize: "11.5px"}} className={`text-center font-xs  text-base border-b border-l border-t border-r border-gray-300`}>
                                                                    <p>{parcela.parcela}</p>
                                                                </td>
                                                                <td onClick={() => handleOpenDataParcela(parcela)} style={{fontSize: "11.5px"}} className={`text-center font-xs  text-base cursor-pointer border-b border-l border-t border-r border-gray-300`}>
                                                                    <p onClick={() => handleOpenDataParcela(parcela)}>{parcela.vencimento.split('-').reverse().join('/')}</p>
                                                                </td>
                                                                <td style={{fontSize: "11.5px"}} className={`text-center font-xs  text-base border-b border-l border-t border-r border-gray-300`}>
                                                                    <p>{`R$ ${parseFloat(valorParcelas).toFixed(2)}`}</p>
                                                                </td>
                                                                {/* <td style={{fontSize: "11.5px"}} className={`text-center font-xs text-base border-b border-r border-gray-300`}>
                                                                    <DeleteIcon color='error' style={{fontSize: '20px', cursor: 'pointer'}}/>
                                                                </td> */}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );
    }
}