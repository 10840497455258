import * as React from "react";
import logo from '../../img/logoLateral.png'
import { Button } from '@mui/material';
import { Link } from 'react-router-dom'; 
export default function HeaderIni() {
  return (
    <div className="bg-white" style={{boxShadow: '0 0 1em black'}}>
      <div className="max-w-7xl mx-auto py-2 px-2 md:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <p className="ml-3 font-medium text-white truncate">
              <Link to='/login'><span className="hidden md:inline"><img src={logo} alt="Logo da GE Sistemas" width="150"/></span></Link>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 md:mt-0 md:w-auto md:ml-3">
            <Link to='/cadastrar-empresa'>
              <Button color="primary" fullWidth size="medium" variant="contained" style={{textTransform: 'lowerCase'}}>Cadastre-se</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}