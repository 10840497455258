import React,{ useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import Loading from "../../loading/Loading";
import ViewListIcon from '@mui/icons-material/ViewList';
import NenhumRegistro from "../../NenhumRegistro";
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Apis from "../../Apis";
import Stack from '@mui/material/Stack';
const ListarEmpresa = () => {
    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify({"id": localStorage.getItem("id")})
        };
                console.log(requestOptions)
        fetch(Apis.urlListarOneEmpresas, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setClientes(result.registros)
                localStorage.setItem('nome', result.registros[0].nome)
                setLoading(false);
            })
    }, []);
    if(loading){
        return(
            <Loading/>
        )
    }
    else{
        if(clientes){
            return(
                <section className="bg-white py-10 lg:py-[80px]">
                    <div className="container">
                        <div className="flex flex-wrap -mx-4">
                            <div className="w-full px-4">
                                <div style={{display: 'flex', alignItems: 'center'}} className="w-full min-w-[160px] text-lg font-semibold text-gray-400 py-0 lg:py-0 border-l border-transparent">
                                    <ViewListIcon/>DADOS DA EMPRESA
                                </div>
                                <div className="max-w-full overflow-x-auto">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className="bg-gray-600 text-center">
                                            <th className="w-1/6 min-w-[360px] text-sm font-semibold text-white py-0 lg:py-1 px-0 lg:px-1 border-l border-gray-600">
                                                DADOS
                                            </th>
                                            <th className="w-1/1 min-w-[100px] text-sm font-semibold text-white py-0 lg:py-1 px-0 lg:px-1">
                                                FONE
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-0 lg:py-1 px-0 lg:px-1">
                                                CIDADE
                                            </th>   
                                            <th className="w-1/1 min-w-[100px] text-sm font-semibold text-white py-0 lg:py-1 px-0 lg:px-1 border-r border-gray-600">
                                                AÇÕES
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clientes.map((cliente, index) => (
                                            <tr key={index} className={`hover:text-blue-500 ${index % 2 == 0 && 'bg-gray-100'}`}>
                                                <td style={{fontSize: "14px"}} className="text-left text-base py-2 px-2 border-b border-l border-gray-300">
                                                    <p className="uppercase"><strong>{cliente.nome}</strong></p>
                                                    <p>Email: {cliente.email}</p>
                                                    <p>CNPJ: {cliente.cnpj}</p>
                                                </td>
                                                <td style={{fontSize: "14px"}} className="text-center uppercase text-base py-2 px-2 border-b border-gray-300">
                                                    {cliente.telefone}
                                                </td>
                                                <td style={{fontSize: "14px"}} className="text-center text-base py-2 px-2 border-b border-gray-300">
                                                    {cliente.cidade}
                                                </td>
                                                <td style={{fontSize: "14px"}} className="text-center text-base py-2 px-2 border-b border-r border-gray-300">
                                                    <Stack direction="row" spacing={1} style={{justifyContent: 'center'}}>
                                                        <IconButton aria-label="delete" color="info" size="small">
                                                            <Link to="/alterar-empresas">
                                                                <EditIcon/>
                                                            </Link>
                                                        </IconButton>
                                                    </Stack>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        else{
            return(
                <NenhumRegistro nome="unidade" pagina="/cadastrar-unidade/0"/>
            )
        }
    }
}
export default ListarEmpresa;