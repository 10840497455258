import React, { useEffect, useState } from 'react';
import './App.css';
import Base from './components/base/Base';
import HeaderIni from './components/header/HeaderIni';
import Login from './components/login/Login';
import Rotas from './components/rotas/Rotas';
import RotasLogin from './components/rotas/RotasLogin';
function App() {
  const [token, setToken] = useState(null);
  useEffect(() => {
    setToken(localStorage.getItem('token'))
  }, [token])
  if(token){
    return (
      <>
        <Base setToken={setToken}/>
        {/* <div style={{marginLeft: 50}}>
          <Rotas/>
        </div> */}
      </>
    );
  }
  else{
    return (
      <>
        <HeaderIni/>
        <RotasLogin setToken={setToken}/>
        {/* <Login setToken={setToken}/> */}
      </>
    );
  }
}
export default App;
// import React from 'react'
// import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
// import './App.css';

// const containerStyle = {
//   width: '100%',
//   height: '100%',
//   textAlign: 'center'
// };

// const center = {
//   lat: -20.469739385294737,
//   lng:  -54.62875384482855
// };

// function App() {
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: "AIzaSyAQ6_tk6Q4QyexoGmR8eu5jGuZpWvj1Hhk"
//   })

//   const [map, setMap] = React.useState(null)

//   const onLoad = React.useCallback(function callback(map) {
//     const bounds = new window.google.maps.LatLngBounds(center);
//     map.fitBounds(bounds);
//     setMap(map)
//   }, [])

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null)
//   }, [])

//   return isLoaded ? (
//       <div style={{width: '100%', height: '100vh'}}>
//         <GoogleMap
//           mapContainerStyle={containerStyle}
//           center={center}
//           zoom={15}
//           onLoad={onLoad}
//           onUnmount={onUnmount}
//         >
//           <Marker position={{lat: -20.469739385294737, lng:  -54.62875384482855}} options={{
//             label: {
//               text: 'GE Sitemas',
//               className: 'map-marker'
//             }
//           }}/>
//           <Marker position={{lat: -20.464679, lng: -54.637479}} options={{
//             label: {
//               text: 'Sushi Ya',
//               className: 'map-marker'
//             }
//           }}/>

//           <Marker style={{backgroundColor: 'blue'}} position={{lat: -20.465591, lng: -54.627830}} options={{
//             label: {
//               text: 'Sushi Ya',
//               className: 'map-marker'
//             }
//           }}/>
//         </GoogleMap>
//       </div>
//   ) : <></>
// }

// export default App;