import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { cpf, cnpj  } from 'cpf-cnpj-validator';
import Loading from '../../loading/Loading';
const AlterarSenhaLink = () => {
    const navigate = useNavigate();
    const [senha, setSenha] = useState('');
    const [loading, setLoading] = useState(false);
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [cnpjEmpresa, setCnpjEmpresa] = useState('');
    const [email, setEmail] = useState('');
    const [validCpfCnpj, setValidCpfCnpj] = useState();
    const parametros = useParams();
    useEffect(() => {
        if(cnpjEmpresa.length >=11 && cnpjEmpresa.length <= 18){
            const resultValid = cpf.isValid(cnpjEmpresa);
            const resultValidCnpj = cnpj.isValid(cnpjEmpresa);
            if(resultValid || resultValidCnpj){
                setValidCpfCnpj(true)
            }
            if(!resultValid && !resultValidCnpj){
                setValidCpfCnpj(false)
            }
        }
    }, [cnpjEmpresa])

    const handleSenha = (e) => {
        setSenha(e.target.value);
    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleConfirmarSenha = (e) => {
        setConfirmarSenha(e.target.value);
    }

    const handleCnpjEmpresa = (e) => {
        setCnpjEmpresa(e.target.value);
    }

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }
    
    const handleCadEmpresa = () => {
        if(!validCpfCnpj || cnpjEmpresa.length < 11){
            alertaErro("CNPJ / CPF invalido")
            return
        }
        if(cnpjEmpresa != '' && email != '' && senha != '' && senha === confirmarSenha){
            setLoading(true)
            const data = {"email" : email, "cnpj"  : cnpjEmpresa, "senha"  : senha, "chave"  : parametros.key};
            console.log(data)
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            };
            fetch('https://gesuportelogico.com.br/api/estabelecimentos/change_pw', requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if(result.retorno.sucesso){
                        navigate(`/alerta-sucesso/${result.retorno.mensagem}&login`);
                    }else{
                        navigate(`/alerta-sucesso/${result.retorno.mensagem}&login`);
                    }
                    setLoading(false)
                })
        }else{
            if(senha != confirmarSenha){
                return alertaErro("Os campos senha e confirmar senha devem ser iguais.");
            }
            alertaErro("Todos os campos devem ser preenchidos.");
        }
    }
    if(loading){
        return(
            <Loading/>
        )
    }
    return(
        <>
            <div className="container">
                <div className="flex items-center w-full justify-center h-screen">
                    <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
                        <div className="flex flex-col items-center space-y-4">
                            <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">Alteração de Senha</h1>
                            <p className="text-sm text-gray-500 text-center w-6/6">Informe os dados solicitados para realizar a alteração da senha.</p>
                            <label className="w-full">CNPJ da empresa:
                                <input type="text" placeholder="CNPJ da empresa" onChange={handleCnpjEmpresa} value={cnpjEmpresa} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>    
                            <label className="w-full">Email:
                                <input type="text" placeholder="Email" onChange={handleEmail} value={email} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>
                            <label className="w-full">Senha:
                                <input type="text" placeholder="Senha" onChange={handleSenha} value={senha} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>    
                            <label className="w-full">Confirmar Senha:
                                <input type="text" placeholder="Confirmar Senha" onChange={handleConfirmarSenha} value={confirmarSenha} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>    
                            <button onClick={handleCadEmpresa} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Gravar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AlterarSenhaLink;