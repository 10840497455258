import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Loading from "../../loading/Loading";
import styles from "./ListaClientes.module.css";
import ViewListIcon from "@mui/icons-material/ViewList";
import NenhumRegistro from "../../NenhumRegistro";
import Tooltip from "@mui/material/Tooltip";
import Excluir from "../../excluir/Excluir";
import ButtonAdd from "../../cadastro/ButtonAdd";
import { Link } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Apis from "../../Apis";
import Stack from "@mui/material/Stack";
const ListaClientes = () => {
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pesquisar, setPesquisar] = useState("");
  const [registros, setRegistros] = useState(10);
  const handlePesquisar = (e) => {
    setPesquisar(e.target.value);
  };

  const handleRegistros = (e) => {
    setRegistros(e.target.value);
  };

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(""),
    };
    fetch(Apis.urlListarClientes, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setClientes(result.registros);
        setLoading(false);
      });
  }, []);
  if (loading) {
    return <Loading />;
  } else {
    if (clientes?.length > 0) {
      return (
        <section className="bg-white py-10 lg:py-[80px]">
          <div className="container">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full px-4">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="w-full min-w-[160px] text-lg font-semibold text-gray-400 py-0 lg:py-0 border-l border-transparent"
                >
                  <ViewListIcon />
                  LISTA DE CLIENTES
                </div>
                <div className={styles.cabecalho}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Pesquisar por Razão Social, CPF ou CNPJ">
                      <input
                        type="text"
                        placeholder="Pesquisar"
                        onChange={handlePesquisar}
                        value={pesquisar}
                        className="border-2 border-gray-300 rounded-lg w-full h-8 px-4"
                      />
                    </Tooltip>
                  </div>
                  <div>
                    <select
                      className="border-2 border-gray-300 rounded-lg w-full h-8"
                      onChange={handleRegistros}
                      value={registros}
                    >
                      {/* <option selected> - Registros - </option> */}
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
                <p className="mt-2 mb-2" style={{ fontSize: 11.5 }}>
                  registros: {clientes?.length}
                </p>
                <div className="max-w-full overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead>
                      <tr className="bg-gray-600 text-center">
                        <th className="w-1/6 min-w-[360px] text-sm font-semibold text-white py-0 lg:py-1 lg:px-1 border-l border-gray-400">
                          CLIENTE
                        </th>
                        <th className="w-1/1 min-w-[150px] text-sm font-semibold text-white py-0 lg:py-1 lg:px-1">
                          CPF-CNPJ
                        </th>
                        <th className="w-1/1 min-w-[150px] text-sm font-semibold text-white py-0 lg:py-1 lg:px-1">
                          CIDADE
                        </th>
                        <th className="w-1/1 min-w-[150px] text-sm font-semibold text-white py-0 lg:py-1 lg:px-1">
                          UF
                        </th>
                        <th className="w-1/1 min-w-[150px] text-sm font-semibold text-white py-0 lg:py-1 lg:px-1 border-r border-gray-400">
                          AÇÕES
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientes
                        .filter((cliente) => {
                          if (pesquisar === "") {
                            return cliente;
                          }
                          if (
                            pesquisar !== "" &&
                            cliente.nome
                              .toLowerCase()
                              .includes(pesquisar.toLowerCase())
                          ) {
                            return cliente;
                          }
                          if (
                            pesquisar !== "" &&
                            cliente.cnpj_cpf
                              .toLowerCase()
                              .includes(pesquisar.toLowerCase())
                          ) {
                            return cliente;
                          }
                        })
                        .map(
                          (cliente, index) =>
                            index < registros && (
                              <tr
                                key={index}
                                className={`hover:text-blue-500 ${
                                  index % 2 == 0 && "bg-gray-100"
                                }`}
                              >
                                <td
                                  className="text-center uppercase py-0 border-b border-l border-gray-300"
                                  style={{ fontSize: 11.5 }}
                                >
                                  {cliente.nome}
                                  {cliente.id}
                                </td>
                                <td
                                  className="text-center py-0 border-b border-gray-300"
                                  style={{ fontSize: 11.5 }}
                                >
                                  {cliente.cnpj_cpf}
                                </td>
                                <td
                                  className="text-center py-0 border-b border-gray-300"
                                  style={{ fontSize: 11.5 }}
                                >
                                  {cliente.cidade}
                                </td>
                                <td
                                  className="text-center py-0 border-b border-gray-300"
                                  style={{ fontSize: 11.5 }}
                                >
                                  {cliente.uf}
                                </td>
                                <td className="text-center py-0 border-b border-r border-gray-300">
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    style={{ justifyContent: "center" }}
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      color="info"
                                      size="small"
                                    >
                                      <Link
                                        to={`/alterar-cliente/${cliente.id}`}
                                      >
                                        <EditIcon />
                                      </Link>
                                    </IconButton>
                                    <IconButton
                                      aria-label="delete"
                                      color="error"
                                    >
                                      <Excluir
                                        redirect="listar-clientes"
                                        url={Apis.urlDeletarClientes}
                                        id={cliente.id}
                                        component="Cliente"
                                        texto={`Deseja Realmente excluir o cliente ${cliente.nome}`}
                                      />
                                    </IconButton>
                                  </Stack>
                                </td>
                              </tr>
                            )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ButtonAdd nome="cliente" pagina="cadastrar-clientes" />
        </section>
      );
    } else {
      return <NenhumRegistro nome="cliente" pagina="/cadastrar-clientes" />;
    }
  }
};
export default ListaClientes;
