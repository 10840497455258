// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from 'firebase/auth';
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBZ5fuUv6599SQudOfv4fPIbG-H8m9HP-g',
  authDomain: 'autenticacao-25-08.firebaseapp.com',
  projectId: 'autenticacao-25-08',
  storageBucket: 'autenticacao-25-08.appspot.com',
  messagingSenderId: '843129427347',
  appId: '1:843129427347:web:cbe4cc76cf5f54da268466'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);