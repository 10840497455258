import React, { useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
export default function Loading(){
    const [open] = useState(true);
    return(
        <>
            <Backdrop sx={{ color: '#fff', display: "flex", flexDirection: "column", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
                <p>Aguarde</p>
            </Backdrop>
        </>
    )
}