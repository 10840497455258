import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { Payments } from '@mui/icons-material';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ApartmentIcon from '@mui/icons-material/Apartment';
import logo from '../../img/perfil.png'
import CloseIcon from '@mui/icons-material/Close';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Link, useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import Apis from '../Apis';
import Rotas from '../rotas/Rotas';
import { FaRegHandshake } from 'react-icons/fa';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Base(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSair = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('nome');
    localStorage.removeItem('id');
    localStorage.removeItem('imagemLogin');
    localStorage.removeItem('@adm_pedido_data_ini_pedido');
    localStorage.removeItem('@adm_pedido_data_ini_visita');
    localStorage.removeItem('@adm_pedido_data_fim_visita');
    localStorage.removeItem('@adm_pedido_data_fim_pedido');
    props.setToken(localStorage.getItem('token'));
    navigate('/');
  }
  return (
    <>
      <Box sx={{ display: 'flex', textAlign: 'justify', padding: "20px" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} style={{backgroundColor: "#425c5a"}}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              <strong style={{textTransform: 'uppercase'}}>{localStorage.getItem('nome')}</strong>
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader style={{backgroundColor: '#425c5a', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography variant="h6" noWrap component="div">
              <strong style={{textTransform: 'uppercase', color: 'white'}}><span style={{color: '#cfcf79'}}>GE</span> PEDIDO</strong>
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <MenuIcon style={{color: 'white'}}/> : <MenuIcon style={{color: 'white'}}/>}
            </IconButton>
          </DrawerHeader>
          <div style={{margin: '0 auto', textAlign: 'center', padding: '40px 10px 40px 10px'}}>
              <Stack spacing={2} style={{margin: '0 auto'}}>
                <Avatar alt="Remy Sharp" src={localStorage.getItem('imagemLogin')} sx={{ width: 100, height: 100 }}  style={{margin: '0 auto'}}/>
              </Stack>
              <h3 style={{color: '#425c5a', textTransform: 'capitalize', marginTop: 10}}>{localStorage.getItem('nome').length > 15 ? `${localStorage.getItem('nome').substring(0, 15)}...` : localStorage.getItem('nome')}</h3>
              {/* <p>{localStorage.getItem('email')}</p> */}
          </div>
          
          <Divider />
          <List>
              <Link to='/home'>
                <ListItem button>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
              </Link>
{/* 
              <Link to='/dashboard'>
                <ListItem button>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="DashBoard" />
                </ListItem>
              </Link> */}

              <Link to='/listar-empresas'>
                <ListItem button>
                  <ListItemIcon>
                    <ApartmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Empresa" />
                </ListItem>
              </Link>

              <Link to='/listar-clientes'>
                <ListItem button>
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary="Clientes" />
                </ListItem>
              </Link>

              <Link to='/listar-representantes'>
                <ListItem button>
                  <ListItemIcon>
                    <EngineeringIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Representantes" />
                </ListItem>
              </Link>

              <Link to='/listar-rotas'>
                <ListItem button>
                  <ListItemIcon>
                    <AltRouteIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Rotas" />
                </ListItem>
              </Link>

              <Link to='/listar-produtos'>
                <ListItem button>
                  <ListItemIcon>
                    <ProductionQuantityLimitsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Produtos" />
                </ListItem>
              </Link>

              <Link to='/listar-unidades'>
                <ListItem button>
                  <ListItemIcon>
                    <LooksOneIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Unidades" />
                </ListItem>
              </Link>

              <Link to='/listar-forma-pagamento'>
                <ListItem button>
                  <ListItemIcon>
                    <Payments/>
                  </ListItemIcon>
                  <ListItemText primary="Formas de Pgto" />
                </ListItem>
              </Link>

              <Link to='/listar-pedidos'>
                <ListItem button>
                  <ListItemIcon>
                    <RequestQuoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Pedidos" />
                </ListItem>
              </Link>

              <Link to='/listar-visitas'>
                <ListItem button>
                  <ListItemIcon>
                    <FaRegHandshake style={{fontSize: 23}} />
                  </ListItemIcon>
                  <ListItemText primary="Visitas" />
                </ListItem>
              </Link>

              {/* <Link to='/listar-producao'>
                <ListItem button>
                  <ListItemIcon>
                    <PrecisionManufacturingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Produção" />
                </ListItem>
              </Link> */}
          </List>
          
          <Divider />
          <List>
              <a href={Apis.urlFacebook} target="_blank">
                <ListItem button>
                  <ListItemIcon>
                    <FacebookIcon />
                  </ListItemIcon>
                  <ListItemText primary="Facebook" />
                </ListItem>
              </a>
              <a href={Apis.urlWhatsapp} target="_blank">
                <ListItem button>
                  <ListItemIcon>
                    <WhatsAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Whatsapp" />
                </ListItem>
              </a>
              <a href={Apis.urlInstagram} target="_blank">
                <ListItem button>
                    <ListItemIcon>
                      <InstagramIcon />
                    </ListItemIcon>
                    <ListItemText primary="Instagram" />
                </ListItem>
              </a>
          </List>
          <Divider />
          <List onClick={handleSair}>
            <ListItem button>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </ListItem>
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          {/* <DrawerHeader /> */}
          <div><Rotas/></div>
        </Box>
      </Box>
    </>
  );
}
