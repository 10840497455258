// import { Box, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import Apis from "../../Apis";
// import Loading from "../../loading/Loading";
// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 360,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 1.5,
// };
// const Dashboard = () => {
//     const [mapaRotas, setMapaRotas] = useState('');
//     const [loading, setLoading] = useState(false);
//     useEffect(() => {
//         setLoading(true);
//         const requestOptions = {
//             method: 'POST',
//             headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
//             body: JSON.stringify({"id": localStorage.getItem("id")})
//         };
//         fetch(Apis.urlListarOneEmpresas, requestOptions)
//             .then(response => response.json())
//             .then((result) => {
//                 if(result.registros[0].link_mapa_google){
//                     var resultado = result.registros[0].link_mapa_google.substring(13);
//                     resultado = resultado.substring(0, resultado.length - 36);
//                     setMapaRotas(resultado)
//                 }else{
//                     setMapaRotas('')
//                 }
//                 setLoading(false);
//             })
//     }, [])
//     if(loading){
//         return(
//             <Loading/>
//         )
//     }
//     if(mapaRotas === ''){
//         return(
//             <div>
//                 <Box sx={style}>
//                     <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign: 'center'}}>
//                         Olá {localStorage.getItem('nome')}
//                     </Typography>
//                     <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: 'center'}}>
//                         O link de rotas não foi informado no cadastro da empresa
//                     </Typography>
//                 </Box>
//             </div>
//         )
//     }
//     if(!loading && mapaRotas !== ''){
//         return(
//             <div style={{display: 'flex', justifyContent: 'center', padding: 20, marginTop: 80}}>
//                 <iframe src={mapaRotas} style={{width: '70%', height:'80vh'}}></iframe>
//             </div>
//         )
//     }
// }
// export default Dashboard;

import React, {useEffect, useState} from "react";
import { Chart } from "react-google-charts";
import _ from 'lodash';
import Apis from "../../Apis";
import { stringify } from "uuid";
export const data = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7],
];

export const options = {
  title: "My Daily Activities",
};
const meses = [{"nome" : "Janeiro", "id" : "01"}, {"nome" : "Fevereiro", "id" : "02"}, {"nome" : "Março", "id" : "03"}, {"nome" : "Abril", "id" : "04"}, {"nome" : "Maio", "id" : "05"}, {"nome" : "Junho", "id" : "06"}, {"nome" : "Julho", "id" : "07"}, {"nome" : "Agosto", "id" : "08"}, {"nome" : "Setembro", "id" : "09"}, {"nome" : "Outubro", "id" : "10"}, {"nome" : "Novembro", "id" : "11"}, {"nome" : "Dezembro", "id" : "12"}];

export default function DashBoard() {
    const [dado, setDado] = useState();
    const [dados, setDados] = useState();
    const dataAtual = new Date();
    var mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    var ano = dataAtual.getFullYear();

    const loadData = (data) => {
        const values = _.groupBy(data, (value) => {
            return value.data.substring(5, 7);
        })
        // console.log(values)

        const result = _.map(values, (value, key) => {
            return[
                key,
                _.sumBy(values[key], (v) => parseFloat(v.natureza_id))
            ]
        })
        // console.log(result)
        return [["Mês", "Pedidos"], ...result];
    }
    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify({})
        };
        fetch(Apis.urlListarPedidos, requestOptions)
            .then(response => response.json())
            .then((result) => {
                var v = result.registros;
                v = v.filter((dado) => dado.data.substring(0, 4) == ano)
                setDado(loadData(v))
                var dadosJa = loadData(v);
                var ms = [];
                for (const [key, value] of Object.entries(dadosJa)) {
                    ms = [...ms, meses.filter((dados) => dados. id== value.toString().substring(0, 2)) ]
                }

                
                var a = `[${JSON.stringify(ms).substring(4)}`;
                var c = [['Mês', 'Pedidos']]
                var sempontoevirgula = a.replace(/{/g, "").replace(/\}/g, "");
                sempontoevirgula = sempontoevirgula.replace(/"nome":/g, "").replace(/\"id":/g, "");
                sempontoevirgula = JSON.parse(sempontoevirgula);

                for (const [key, value] of Object.entries(sempontoevirgula)) {
                    c = [...c, value]
                }
                c = JSON.stringify(c)
                c = c.replace(/'/g, "").replace(/'/g, "");
                console.log(c)
                // setDado(JSON.parse(c))
            })

    }, []);
  return (
            <div style={{justifyContent: 'center', padding: 20, marginTop: 80}}>
    
    <Chart
      chartType="PieChart"
      data={dado}
      options={options}
      width={"100%"}
      height={"400px"}
    />

    <p>{JSON.stringify(dado)}</p>
    </div>
  );
}
