const URL_BASE = "https://gesuportelogico.com.br/api/";
const Apis = {
  // LOGIN
  // login
  urlLogin: `${URL_BASE}usuarios/login`,
  // FIM LOGIN

  // CADASTROS
  // cadastrar cliente
  urlCadastrarClientes: `${URL_BASE}clientes/create`,
  // cadastrar rotas
  urlCadastrarRotas: `${URL_BASE}rotas/create`,
  // cadastrar empresa
  urlCadastrarEmpresas: `${URL_BASE}estabelecimentos/create`,
  // cadastrar pedido de venda
  urlCadastrarPedidoVenda: `${URL_BASE}pedidosvenda/create`,
  // cadastrar produto
  urlCadastrarProdutos: `${URL_BASE}produtos/create`,
  // cadastrar representante
  urlCadastrarRepresentantes: `${URL_BASE}representantes/create`,
  // cadastrar unidade
  urlCadastrarUnidades: `${URL_BASE}unidades/create`,
  // cadastrar forma de pagamento
  urlCadastrarFormaPagamento: `${URL_BASE}formaspagamento/create`,
  // FIM CADASTROS

  // LISTAGEM
  // listar formas de pagamentos
  urlListarFormasPagamento: `${URL_BASE}formaspagamento/read`,
  // listar clientes
  urlListarClientes: `${URL_BASE}clientes/read`,
  // listar rotas
  urlListarRotas: `${URL_BASE}rotas/read`,
  // listar 1 clientes
  urlListarOneClientes: `${URL_BASE}clientes/read_one`,
  // listar pedidos de venda
  urlListarPedidos: `${URL_BASE}pedidosvenda/read`,
  // listar visitas
  urlListarVisitas: `${URL_BASE}visitas/read`,
  // listar 1 pedidos de venda
  urlListarOnePedidos: `${URL_BASE}pedidosvenda/read_one`,
  // listar 1 rota
  urlListarOneRotas: `${URL_BASE}rotas/read_one`,
  // listar produção
  urlListarProducao: `${URL_BASE}producao/read`,
  // listar imagem produção
  urlListarImagemProducao: `${URL_BASE}producao/getimage`,
  // listar produtos
  urlListarProdutos: `${URL_BASE}produtos/read`,
  // listar 1 produto
  urlListarOneProdutos: `${URL_BASE}produtos/read_one`,
  // listar 1 forma de pagamento
  urlListarOneFormaPagamento: `${URL_BASE}formaspagamento/read_one`,
  // listar representantes
  urlListarRepresentantes: `${URL_BASE}representantes/read`,
  // listar 1 unidades
  urlListarOneRepresentantes: `${URL_BASE}representantes/read_one`,
  // listar unidades
  urlListarUnidades: `${URL_BASE}unidades/read`,
  // listar 1 unidades
  urlListarOneUnidades: `${URL_BASE}unidades/read_one`,
  // listar 1 empresa
  urlListarOneEmpresas: `${URL_BASE}estabelecimentos/read_one`,
  // FIM LISTAGEM

  // ALTERAÇÃO
  // alterar forma de pagamento
  urlAlterarFormaPagamento: `${URL_BASE}formaspagamento/update`,
  // alterar rotas
  urlAlterarRotas: `${URL_BASE}rotas/update`,
  // alterar empresa
  urlAlterarEmpresa: `${URL_BASE}estabelecimentos/update`,
  // alterar cliente
  urlAlterarCliente: `${URL_BASE}clientes/update`,
  // alterar pedido de venda
  urlAlterarPedidoVenda: `${URL_BASE}pedidosvenda/update`,
  // alterar produto
  urlAlterarProduto: `${URL_BASE}produtos/update`,
// alterar imagem produto 
  urlAlterarImgProduto: `${URL_BASE}produtos/add_image`,
  // alterar representante
  urlAlterarRepresentante: `${URL_BASE}representantes/update`,
  // alterar unidade
  urlAlterarUnidade: `${URL_BASE}unidades/update`,
  // alterar senha
  urlAlterarSenha: `${URL_BASE}estabelecimentos/email_change_pw`,
  // FIM ALTERAÇÃO

  // DELETAR
  // deletar cliente
  urlDeletarClientes: `${URL_BASE}clientes/delete`,
  // deletar rotas
  urlDeletarRotas: `${URL_BASE}rotas/delete`,
  // deletar pedido de venda
  urlDeletarPedidoVenda: `${URL_BASE}pedidosvenda/delete`,
  // deletar producao
  urlDeletarProducao: `${URL_BASE}producao/delete`,
  // deletar visita
  urlDeletarVisita: `${URL_BASE}visitas/delete`,
  // deletar produto
  urlDeletarProdutos: `${URL_BASE}produtos/delete`,
  // deletar representante
  urlDeletarRepresentantes: `${URL_BASE}representantes/delete`,
  // deletar unidade
  urlDeletarUnidades: `${URL_BASE}unidades/delete`,
  // deletar forma de pagamento
  urlDeletarFormaPagamento: `${URL_BASE}formaspagamento/delete`,
  // FIM DELETAR

  // REDES SOCIAIS
  urlFacebook: "https://www.facebook.com/gesuportelogico",
  urlWhatsapp: "https://api.whatsapp.com/send?phone=5567991986596&text=",
  urlInstagram: "https://www.instagram.com/gesistemas/",
  // FIM REDES SOCIAIS
};
export default Apis;
