import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../loading/Loading";
import Apis from "../../Apis";
import swal from "sweetalert";
const AlterarRota = () => {
    const [sigla, setSigla] = useState('');
    const [descricao, setDescricao] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const parametro = useParams();
    const handleSigla = (e) => {
        setSigla(e.target.value);
    }

    const handleDescricao = (e) => {
        setDescricao(e.target.value);
    }

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }

    useEffect(() => {
        setLoading(true)
        const requestOptions = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify({"id": parametro.id})
        };
        fetch(Apis.urlListarOneRotas, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if(result.retorno.sucesso){
                    setDescricao(result.registros[0].descricao)
                    setSigla(result.registros[0].nome)
                }else{
                    alertaErro(result.retorno.mensagem)
                    navigate("/listar-rotas")
                }
                setLoading(false)
            })
    }, []);
    const handleSubmit = () => {
        const requestOptions = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify({"id": parametro.id, "nome": sigla, "descricao": descricao})
        };
        fetch(Apis.urlAlterarRotas, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if(result.retorno.sucesso){
                    navigate(`/alerta-sucesso/${result.retorno.mensagem}&listar-rotas`);
                }else{
                    alertaErro(result.retorno.mensagem)
                }
            })
    }
    if(loading){
        return(
            <Loading/>
        )
    }else{
        return(
            <>
                <div style={{padding: 15, paddingTop: 0}}>
                    <div className="flex items-center w-full justify-center h-screen">
                        <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
                            <div className="flex flex-col items-center space-y-4">
                                <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">Alteração de Unidade</h1>
                                <p className="text-sm text-gray-500 text-center w-6/6">Informe os dados solicitados para realizar a alteração da  unidade.</p>
                                <label className="w-full">Sigla:
                                    <input type="text" placeholder="Sigla" onChange={handleSigla} value={sigla} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                                </label>
                                <label className="w-full">Descrição:
                                    <input type="text" placeholder="Descrição" onChange={handleDescricao} value={descricao} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                                </label>
                                <button onClick={handleSubmit} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Alterar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default AlterarRota;