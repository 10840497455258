import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Snackbar } from "@mui/material";
import { Navigate, useNavigate, useParams, Link } from "react-router-dom";
import Loading from "../../loading/Loading";
import Apis from "../../Apis";
import swal from "sweetalert";
import axios from "axios";
const AlterarProduto = () => {
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [idUnidade, setIdUnidade] = useState("");
  const [preco, setPreco] = useState("");
  const [status, setStatus] = useState("");
  const [estoque, setEstoque] = useState("");
  const [tipo, setTipo] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const parametro = useParams();
  const [unidadeBd, setUnidadeBd] = useState([]);
  const [imagem, setImagem] = useState("");

  const handleCodigo = (e) => {
    setCodigo(e.target.value);
  };
  const handleDescricao = (e) => {
    setDescricao(e.target.value);
  };
  const handleIdUnidade = (e) => {
    setIdUnidade(e.target.value);
  };
  const handlePreco = (e) => {
    setPreco(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleEstoque = (e) => {
    setEstoque(e.target.value);
  };
  const handleTipo = (e) => {
    setTipo(e.target.value);
  };

  const handleImagem = (e) => {
    setImagem(e.target.files[0]);
  };

  const alertaErro = (texto) => {
    swal({
      title: "Atenção",
      text: texto,
      icon: "error",
      dangerMode: true,
      className: "alertas-cad-empresa",
    });
  };
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(""),
    };
    fetch(Apis.urlListarUnidades, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setUnidadeBd(result.registros);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ id: parametro.id }),
    };
    fetch(Apis.urlListarOneProdutos, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.retorno.sucesso) {
          setCodigo(result.registros[0].codigo);
          setDescricao(result.registros[0].descricao);
          setIdUnidade(result.registros[0].unidade_id);
          setPreco(result.registros[0].preco);
          setStatus(result.registros[0].status);
          setEstoque(result.registros[0].estoque);
          setTipo(result.registros[0].tipo);
        } else {
          alertaErro(result.retorno.mensagem);
          navigate("/listar-produtos");
        }
        setLoading(false);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    data.append("id", parametro.id);
    data.append("meu_id", 0);
    data.append("imagem", imagem);
    if (
      codigo === "" ||
      descricao === "" ||
      idUnidade === "" ||
      preco === "" ||
      status === "" ||
      estoque === "" ||
      tipo === ""
    ) {
      alertaErro("Todos os campos devem ser preenchidos!");
    }
    if (
      codigo !== "" &&
      descricao !== "" &&
      idUnidade !== "" &&
      preco !== "" &&
      status !== "" &&
      estoque !== "" &&
      tipo !== ""
    ) {
      setLoading(true);
      const requestOptions = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${localStorage.getItem("token")}`,
        },
      };

      await axios
        .post(
          localStorage.getItem("usaMerchant") == 0
            ? Apis.urlAlterarProduto
            : Apis.urlAlterarImgProduto,
          data,
          requestOptions
        )
        .then((response) => {
          swal({
            title: "Sucesso",
            text: response.data.retorno.mensagem,
            icon: "success",
            dangerMode: true,
            className: "alertas-cad-empresa",
          });
          navigate(-1);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          alertaErro(error.response.data.retorno.mensagem);
          setLoading(false);
        });
    }
  };
  if (idUnidade === "cadastrarUnidade") {
    navigate(`/cadastrar-unidade/${parametro.id}`);
  }
  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <div style={{ padding: 15, paddingTop: 0, marginTop: 80 }}>
          <div className="flex items-center w-full justify-center h-screen">
            <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
              <form
                onSubmit={handleSubmit}
                className="flex flex-col items-center space-y-4"
              >
                <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">
                  Alteração de Produtos
                </h1>
                {localStorage.getItem("usaMerchant") == 0 && (
                  <>
                    <p className="text-sm text-gray-500 text-center w-6/6">
                      Informe os dados do produto para realizar a alteração.
                    </p>
                    <input
                      type="hidden"
                      placeholder="Código"
                      onChange={handleCodigo}
                      value={codigo}
                      className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"
                      name="codigo"
                    />
                    <label className="w-full">
                      Descrição:
                      <input
                        type="text"
                        placeholder="Descrição"
                        onChange={handleDescricao}
                        value={descricao}
                        className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"
                        name="descricao"
                      />
                    </label>
                    {/* <input type="text" placeholder="Unidade" onChange={handleUnidade} value={unidade} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/> */}
                    {unidadeBd ? (
                      <label className="w-full">
                        Unidade:
                        <select
                          onChange={handleIdUnidade}
                          value={idUnidade}
                          className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"
                          name="unidade_id"
                        >
                          <option selected value="vazio">
                            {" "}
                            - selecione a unidade -{" "}
                          </option>
                          {unidadeBd.map((unidades) => (
                            <option value={unidades.id} key={unidades.id}>
                              {unidades.sigla}
                            </option>
                          ))}
                          <option value="cadastrarUnidade">
                            Cadastrar unidade
                          </option>
                        </select>
                      </label>
                    ) : (
                      <Link
                        to={`/cadastrar-unidade/${parametro.id}`}
                        className="w-full"
                      >
                        <button className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">
                          Cadastrar Unidade
                        </button>
                      </Link>
                    )}
                    <label className="w-full">
                      Preço:
                      <input
                        type="number"
                        placeholder="Preço"
                        onChange={handlePreco}
                        value={preco}
                        className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"
                        name="preco"
                      />
                    </label>
                    <label className="w-full">
                      Status:
                      <select
                        onChange={handleStatus}
                        value={status}
                        className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"
                        name="status"
                      >
                        <option selected> - selecione o status - </option>
                        <option value="0">Ativo</option>
                        <option value="1">Inativo</option>
                      </select>
                    </label>
                    <label className="w-full">
                      Quantidade em estoque:
                      <input
                        type="text"
                        placeholder="Quantidade em estoque"
                        onChange={handleEstoque}
                        value={estoque}
                        className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"
                        name="estoque"
                      />
                    </label>
                    <label className="w-full">
                      Tipo:
                      <select
                        onChange={handleTipo}
                        value={tipo}
                        className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"
                        name="tipo"
                      >
                        <option selected>- selecione o tipo - </option>
                        <option value="0">Mercadoria para Revenda</option>
                        <option value="1">Matéria-Prima</option>
                        <option value="2">Embalagem</option>
                        <option value="3">Produto em Processo</option>
                        <option value="4">Produto Acabado</option>
                        <option value="5">Subproduto</option>
                        <option value="6">Produto Intermediário</option>
                        <option value="7">Material de Uso e Consumo</option>
                        <option value="8">Ativo Imobilizado</option>
                        <option value="9">Serviços</option>
                        <option value="10">Outros insumos</option>
                        <option value="99">Outras</option>
                      </select>
                    </label>
                  </>
                )}
                <label className="w-full">
                  imagem:
                  <input
                    style={{ paddingTop: 8 }}
                    type="file"
                    accept="image/*"
                    placeholder="Quantidade em estoque"
                    className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"
                    onChange={handleImagem}
                  />
                </label>
                <button className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">
                  Alterar
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
};
export default AlterarProduto;
