import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cpf, cnpj  } from 'cpf-cnpj-validator';
import Apis from "../../Apis";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Chip from '@mui/material/Chip';
import swal from "sweetalert";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

const CadastrarUnidade = () => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [cnpjCpf, setCnpjCpf] = useState('');
    const [validCpfCnpj, setValidCpfCnpj] = useState();
    const [rotasServer, setRotasServer] = useState([]);
    const [rotas, setRotas] = useState([]);
    const navigate = useNavigate();
    const parametro = useParams();
 
    const handleNome = (e) => {
        setNome(e.target.value);
    }
    const handlesmail = (e) => {
        setEmail(e.target.value);
    }
    const handlesetjCpf = (e) => {
        setCnpjCpf(e.target.value);
    }
    const handleRotas = (rota) => {
        var rotasExiste = false;
        for(var i = 0; i < rotas.length; i++){
            if(rotas[i].id === rota.id){
                rotasExiste = true;
            }
        }
        if(!rotasExiste){
            setRotas([...rotas, {"id": rota.id, "nome": rota.nome}])
        }else{
            handleDelRota(rota)
        }
    }
    const handleDelRota = (rota) => {
        setRotas(rotas.filter((rotas) => rotas.id !== rota.id))
    }

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }

    useEffect(() => {
        if(cnpjCpf.length >=11 && cnpjCpf.length <= 18){
            const resultValid = cpf.isValid(cnpjCpf);
            const resultValidCnpj = cnpj.isValid(cnpjCpf);
            if(resultValid || resultValidCnpj){
                setValidCpfCnpj(true)
            }
            if(!resultValid && !resultValidCnpj){
                setValidCpfCnpj(false)
            }
        }
    }, [cnpjCpf])

    const handleSubmit = () => {
        if(validCpfCnpj === false || cnpjCpf.length < 11){
            alertaErro("CPF/CNPJ inválido!")
        }
        if(validCpfCnpj === true){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
                body: JSON.stringify({"nome": nome, "email": email, "cnpj_cpf": cnpjCpf, "rotas": rotas})
            };
            fetch(Apis.urlCadastrarRepresentantes, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if(result.retorno.sucesso){
                        if(parametro.id === "0"){
                            navigate(`/alerta-sucesso/${result.retorno.mensagem}&listar-representantes`);
                        }
                        if(parametro.id === "1"){
                            navigate(`/alerta-sucesso/${result.retorno.mensagem}&cadastrar-pedido`);
                        }
                    }
                    else{
                        alertaErro(result.retorno.mensagem);
                    }
                })
        }
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify({})
        };
        fetch(Apis.urlListarRotas, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setRotasServer(result.registros)
            })
    }, []);

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    return(
        <>
            <div style={{padding: 15, paddingTop: 0}}>
                <div className="flex items-center w-full justify-center h-screen">
                    <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
                        <div className="flex flex-col items-center space-y-4">
                            <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">Cadastro de Representante</h1>
                            <p className="text-sm text-gray-500 text-center w-6/6">Informe os dados solicitados para realizar o cadastro da  representante.</p>
                            <label className="w-full">Nome:
                                <input type="text" placeholder="Nome" onChange={handleNome} value={nome} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>
                            <label className="w-full">Email:
                                <input type="text" placeholder="Email" onChange={handlesmail} value={email} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>
                            <label className="w-full" style={{textAlign: 'left'}}>CNPJ / CPF:
                                <input type="text" placeholder="CNPJ / CPF" onChange={handlesetjCpf} value={cnpjCpf} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>
                            {rotasServer !== undefined &&
                                <label className="w-full" onClick={handleOpenModal}>Rotas:
                                    <div className="border-2 border-gray-300 flex items-center rounded-lg w-full h-12 px-4">
                                            {rotas.length > 0 ?
                                                rotas.map((rota) => (
                                                    <Chip className="uppercase" style={{margin: 2}} onClick={handleOpenModal} key={rota.id} label={rota.nome} />
                                                ))
                                                :
                                                <label>Selecione as rotas</label>
                                            }
                                    </div>
                                </label>
                            }
                            <button onClick={handleSubmit} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Cadastrar</button>
                        </div>
                    </div>
                </div>
            </div>
            {rotasServer !== undefined &&
                <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Seleção de Rotas
                        </Typography>
                                <div className="mt-5">
                                    {rotas.map((rota) => (
                                        <Chip className="uppercase" style={{margin: 2}} onClick={() => handleDelRota(rota)} key={rota.id} label={rota.nome} />
                                    ))}
                                </div>
                        <div>
                            {rotasServer.map((rotaServer) => (
                                <button onClick={() => handleRotas(rotaServer)} className="bg-blue-400 mt-2 uppercase text-white rounded-md font-semibold px-4 py-3 w-full">
                                    {rotaServer.nome}
                                </button>
                            ))}
                        </div>
                    </Box>
                </Modal>
            }
        </>
    )
}
export default CadastrarUnidade;