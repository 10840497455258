import React, { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import Loading from "../../loading/Loading";
import styles from './ListaUnidade.module.css';
import ViewListIcon from '@mui/icons-material/ViewList';
import NenhumRegistro from "../../NenhumRegistro";
import Tooltip from '@mui/material/Tooltip';
import Excluir from "../../excluir/Excluir";
import ButtonAdd from "../../cadastro/ButtonAdd";
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Apis from "../../Apis";
import Stack from '@mui/material/Stack';
const ListaUnidade = () => {
    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pesquisar, setPesquisar] = useState('');
    const [registros, setRegistros] = useState(10);
    const handlePesquisar = (e) => {
        setPesquisar(e.target.value);
    }

    const handleRegistros = (e) => {
        setRegistros(e.target.value);
    }

    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` },
            body: JSON.stringify({})
        };
        fetch(Apis.urlListarUnidades, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setClientes(result.registros)
                setLoading(false);
            })
    }, []);
    if (loading) {
        return (
            <Loading />
        )
    }
    else {
        if (clientes) {
            return (
                <section className="bg-white py-10 lg:py-[80px]">
                    <div className="container">
                        <div className="flex flex-wrap -mx-4">
                            <div className="w-full px-4">
                                <div style={{ display: 'flex', alignItems: 'center' }} className="w-full min-w-[160px] text-lg font-semibold text-gray-400 py-0 lg:py-0 border-l border-transparent">
                                    <ViewListIcon />LISTA DE UNIDADES
                                </div>
                                <div className={styles.cabecalho}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Tooltip title="Pesquisar por Descrição ou Sigla">
                                            <input type="text" placeholder="Pesquisar" onChange={handlePesquisar} value={pesquisar} className="border-2 rounded-lg w-full h-8 px-4" />
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <select className="border-2 rounded-lg w-full h-8 px-4" onChange={handleRegistros} value={registros}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                                <p className="mt-2 mb-2" style={{ fontSize: 11.5 }}>registros: {clientes.length}</p>
                                <div className="max-w-full overflow-x-auto">
                                    <table className="table-auto w-full">
                                        <thead>
                                            <tr className="bg-gray-600 text-center">
                                                <th className="w-1/6 min-w-[360px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l border-gray-600">
                                                    ID
                                                </th>
                                                <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                    DESCRIÇÃO
                                                </th>
                                                <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                    SIGLA
                                                </th>
                                                <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-r border-gray-600">
                                                    AÇÕES
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clientes.filter((cliente) => {
                                                if (pesquisar === '') {
                                                    return cliente
                                                }
                                                if (pesquisar !== '' && cliente.descricao.toLowerCase().includes(pesquisar.toLowerCase())) {
                                                    return cliente
                                                }
                                                if (pesquisar !== '' && cliente.sigla.toLowerCase().includes(pesquisar.toLowerCase())) {
                                                    return cliente
                                                }
                                            }).map((cliente, index) => (
                                                index < registros &&
                                                <tr key={index} className={`hover:text-blue-500 ${index % 2 == 0 && 'bg-gray-100'}`}>
                                                    <td style={{ fontSize: "11.5px" }} className="text-center text-base border-b border-l border-gray-300">
                                                        {cliente.id}
                                                    </td>
                                                    <td style={{ fontSize: "11.5px" }} className="text-center uppercase text-base border-b border-gray-300">
                                                        {cliente.descricao}
                                                    </td>
                                                    <td style={{ fontSize: "11.5px" }} className="text-center text-base border-b border-gray-300">
                                                        {cliente.sigla}
                                                    </td>
                                                    <td className="text-center py-0 border-b border-r border-gray-300">
                                                        <Stack direction="row" spacing={1} style={{ justifyContent: 'center' }}>
                                                            {localStorage.getItem('usaMerchant') == 0 &&
                                                                <IconButton aria-label="delete" color="info" size="small">
                                                                    <Link to={`/alterar-unidade/${cliente.id}`}>
                                                                        <EditIcon />
                                                                    </Link>
                                                                </IconButton>
                                                            }
                                                            <IconButton aria-label="delete" color="error">
                                                                <Excluir redirect="listar-unidades" url={Apis.urlDeletarUnidades} id={cliente.id} component="Unidade" texto={`Deseja Realmente excluir a unidade ${cliente.descricao}`} />
                                                            </IconButton>
                                                        </Stack>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {localStorage.getItem('usaMerchant') == 0 && <ButtonAdd nome='unidade' pagina='cadastrar-unidade/0' />}
                </section>
            )
        }
        else {
            return (
                <NenhumRegistro nome="unidade" pagina="/cadastrar-unidade/0" />
            )
        }
    }
}
export default ListaUnidade;