import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
export function AlertaSucesso() {
    const navigate = useNavigate();
    const parametro = useParams();
    useEffect(() => {
            swal({
                title: "Atenção",
                text: parametro.texto,
                icon: "success",
                dangerMode: true,
                className: 'alertas-cad-empresa'
            })
            .then((willDelete) => {
                if (willDelete) {
                    navigate(`/${parametro.pagina}`)
                } else {
                    navigate(`/${parametro.pagina}`)
                }
            });
    }, [])

    return (
        <></>
    );
}