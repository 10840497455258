import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Apis from '../../Apis.js';
import swal from 'sweetalert';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import Loading from '../../loading/Loading.js';
import axios from 'axios';
const AlterarSenha = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [cnpjEmpresa, setCnpjEmpresa] = useState('');
    const [validCpfCnpj, setValidCpfCnpj] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (cnpjEmpresa.length >= 11 && cnpjEmpresa.length <= 18) {
            const resultValid = cpf.isValid(cnpjEmpresa);
            const resultValidCnpj = cnpj.isValid(cnpjEmpresa);
            if (resultValid || resultValidCnpj) {
                setValidCpfCnpj(true)
            }
            if (!resultValid && !resultValidCnpj) {
                setValidCpfCnpj(false)
            }
        }
    }, [cnpjEmpresa])

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleCnpjEmpresa = (e) => {
        setCnpjEmpresa(e.target.value);
    }

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }

    const handleCadEmpresa = async () => {
        if (validCpfCnpj === false || cnpjEmpresa.length < 11) {
            alertaErro("CPF/CNPJ inválido!")
            return
        }
        if (email != '' && cnpjEmpresa != '' && validCpfCnpj === true) {
            setLoading(true)
            const data = { "email": email, "cnpj": cnpjEmpresa };
            console.log(data)
            const requestOptions = {
                headers: { 'Content-Type': 'application/json' },
            };

            await axios.post(Apis.urlAlterarSenha, data, requestOptions)
                .then((response) => {
                    if (response.data.retorno.sucesso) {
                        navigate(`/alerta-sucesso/${response.data.retorno.mensagem}&login`);
                    } else {
                        alertaErro(response.data.retorno.mensagem)
                    }
                    setLoading(false)
                })
        } else {
            alertaErro("Todos os campos devem ser preenchidos!")
        }
    }
    if (loading) {
        return (
            <Loading />
        )
    }
    return (
        <>
            <div className="container">
                <div className="flex items-center w-full justify-center h-screen">
                    <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
                        <div className="flex flex-col items-center space-y-4">
                            <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">Cadastro de Empresa</h1>
                            <p className="text-sm text-gray-500 text-center w-6/6">Informe os dados solicitados para realizar o cadastro da  empresa.</p>
                            <label className="w-full">CNPJ da empresa:
                                <input type="text" placeholder="CNPJ da empresa" onChange={handleCnpjEmpresa} value={cnpjEmpresa} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                            </label>
                            <label className="w-full">Email:
                                <input type="text" placeholder="Email" onChange={handleEmail} value={email} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                            </label>
                            <button onClick={handleCadEmpresa} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Gravar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AlterarSenha;