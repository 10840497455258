import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Apis from "../../Apis";
import swal from "sweetalert";
import { Box, Chip, Modal, Typography } from "@mui/material";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}
const CadastrarRotas = () => {
    const [sigla, setSigla] = useState('');
    const [descricao, setDescricao] = useState('');
    const [diasSemana, setDiasSemana] = useState([{ 'id': 0, 'dias_semana_venda': 'domingo', 'valor': 0 }, { 'id': 1, 'dias_semana_venda': 'segunda-feira', 'valor': 0 }, { 'id': 2, 'dias_semana_venda': 'terça-feira', 'valor': 0 }, { 'id': 3, 'dias_semana_venda': 'quarta-feira', 'valor': 0 }, { 'id': 4, 'dias_semana_venda': 'quinta-feira', 'valor': 0 }, { 'id': 5, 'dias_semana_venda': 'sexta-feira', 'valor': 0 }, { 'id': 6, 'dias_semana_venda': 'sábado', 'valor': 0 }]);
    const diasSemanaCopia = diasSemana.filter((item) => item.valor == 1);
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    const modal = () => {
        setOpenModal(!openModal);
    }
    const handleSigla = (e) => {
        setSigla(e.target.value);
    }
    const handleDescricao = (e) => {
        setDescricao(e.target.value);
    }

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }

    const handleDiasSemana = (dados) => {
        var dCopy = diasSemana;
        for (var i = 0; i < diasSemana.length; i++) {
            if (diasSemana[i].id == dados) {
                if (dCopy[i].valor == 0) {
                    dCopy[i].valor = 1;
                } else {
                    dCopy[i].valor = 0;
                }
            }
        }
        setDiasSemana(dCopy)
        setOpenModal(false);
    }
    const handleSubmit = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` },
            body: JSON.stringify({ "nome": sigla, "descricao": descricao, "dias_semana_venda": `${diasSemana[0].valor}${diasSemana[1].valor}${diasSemana[2].valor}${diasSemana[3].valor}${diasSemana[4].valor}${diasSemana[5].valor}${diasSemana[6].valor}` })
        };
        console.log(requestOptions);
        fetch(Apis.urlCadastrarRotas, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.retorno.sucesso) {
                    navigate(`/alerta-sucesso/${result.retorno.mensagem}&listar-rotas`);
                } else {
                    alertaErro(result.retorno.mensagem)
                }
            })
    }
    return (
        <>
            <div style={{ padding: 15, paddingTop: 0 }}>
                <div className="flex items-center w-full justify-center h-screen">
                    <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
                        <div className="flex flex-col items-center space-y-4">
                            <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">Cadastro de Rota</h1>
                            <p className="text-sm text-gray-500 text-center w-6/6">Informe os dados solicitados para realizar o cadastro da rota.</p>
                            <label className="w-full">Nome:
                                <input type="text" placeholder="Sigla" onChange={handleSigla} value={sigla} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" />
                            </label>
                            <label className="w-full">Descrição:
                                <textarea type="text" placeholder="Descrição" onChange={handleDescricao} value={descricao} className="border-2 border-gray-300 rounded-lg w-full h-20 px-4" />
                            </label>

                            <label className="w-full cursor-pointer" onClick={modal}>Dias:
                                <div style={{ flexWrap: 'wrap' }} className="border-2 border-gray-300 flex items-center warap rounded-lg w-full px-4 p-3">
                                    {diasSemanaCopia?.length > 0 ?
                                        diasSemanaCopia?.map((item, index) => (
                                            <Chip className="uppercase cursor-pointer" onClick={modal} style={{ margin: 2 }} key={index} label={item.dias_semana_venda} />
                                        ))
                                        :
                                        <label className="cursor-pointer">Selecione os dias para a rota</label>
                                    }
                                </div>
                            </label>
                            <button onClick={handleSubmit} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Cadastrar</button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal open={openModal} onClose={modal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Seleção de Dias para Rota
                    </Typography>
                    <div className="mb-2">
                        <input type="button" value='domingo' onClick={() => handleDiasSemana(0)} className="border-2 w-full cursor-pointer border-gray-300 rounded-lg px-4" />
                    </div>
                    <div className="mb-2 mt-5">
                        <input type="button" value='segunda-feira' onClick={() => handleDiasSemana(1)} className="border-2 w-full cursor-pointer border-gray-300 rounded-lg px-4" />
                    </div>
                    <div className="mb-2">
                        <input type="button" value='terça-feira' onClick={() => handleDiasSemana(2)} className="border-2 w-full cursor-pointer border-gray-300 rounded-lg px-4" />
                    </div>
                    <div className="mb-2">
                        <input type="button" value='quarta-feira' onClick={() => handleDiasSemana(3)} className="border-2 w-full cursor-pointer border-gray-300 rounded-lg px-4" />
                    </div>
                    <div className="mb-2">
                        <input type="button" value='quinta-feira' onClick={() => handleDiasSemana(4)} className="border-2 w-full cursor-pointer border-gray-300 rounded-lg px-4" />
                    </div>
                    <div className="mb-2">
                        <input type="button" value='sexta-feira' onClick={() => handleDiasSemana(5)} className="border-2 w-full cursor-pointer border-gray-300 rounded-lg px-4" />
                    </div>
                    <div className="mb-2">
                        <input type="button" value='sábado' onClick={() => handleDiasSemana(6)} className="border-2 w-full cursor-pointer border-gray-300 rounded-lg px-4" />
                    </div>
                </Box>
            </Modal>
        </>
    )
}
export default CadastrarRotas;