import React,{ useEffect, useState } from "react";
import Loading from "../../loading/Loading";
import styles from './ListaVisitas.module.css';
import ViewListIcon from '@mui/icons-material/ViewList';
import Typography from '@mui/material/Typography';
import Excluir from "../../excluir/Excluir";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Apis from "../../Apis";
const styleDataFiltro = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "400px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const ListaVisitas = () => {
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, '0');
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();
    var dataAtual = ano + '-' + mes + '-' + dia;

    const [dataIni, setDataIni] = useState(localStorage.getItem('@adm_pedido_data_ini_visita') ? localStorage.getItem('@adm_pedido_data_ini_visita') : dataAtual);
    const [dataFim, setDataFim] = useState(localStorage.getItem('@adm_pedido_data_fim_visita') ? localStorage.getItem('@adm_pedido_data_fim_visita') : dataAtual);
    const [dataInicialLoc, setDataInicialLoc] = useState(localStorage.getItem('@adm_pedido_data_fim_visita') ? localStorage.getItem('@adm_pedido_data_fim_visita') : dataAtual);
    const [dataFinalLoc, setDataFinalLoc] = useState(localStorage.getItem('@adm_pedido_data_fim_visita') ? localStorage.getItem('@adm_pedido_data_fim_visita') : dataAtual);
    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pesquisar, setPesquisar] = useState('');
    const [registros, setRegistros] = useState(10);
    const handlePesquisar = (e) => {
        setPesquisar(e.target.value);
    }
    

    const handleRegistros = (e) => {
        setRegistros(e.target.value);
    }

    const handleDataIni = (e) => {
        setDataIni(e.target.value);
        localStorage.setItem('@adm_pedido_data_ini_visita', e.target.value)
    }

    const handleDataFim = (e) => {
        setDataFim(e.target.value);
        localStorage.setItem('@adm_pedido_data_fim_visita', e.target.value)
    }
    useEffect(() => {
        setDataInicialLoc(localStorage.getItem('@adm_pedido_data_ini_visita') ? localStorage.getItem('@adm_pedido_data_ini_visita') : dataIni)
        setDataFinalLoc(localStorage.getItem('@adm_pedido_data_fim_visita') ? localStorage.getItem('@adm_pedido_data_fim_visita') : dataFim)
    }, [dataIni, dataFim])

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify({'data_ini' : dataInicialLoc, 'data_fin' : dataFinalLoc})
        };
        fetch(Apis.urlListarVisitas, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if(result.retorno.sucesso){
                    setClientes(result.registros)
                }
            })
    }, [dataInicialLoc, dataFinalLoc]);
    if(loading){
        return(
            <Loading/>
        )
    }
    else{
        if(clientes.length > 0){
            return(
                <section className="bg-white py-10 lg:py-[80px]">
                    <div className="container">
                        <div className="flex flex-wrap -mx-4">
                            <div className="w-full px-4">
                                <div style={{display: 'flex', alignItems: 'center'}} className="w-full min-w-[160px] text-lg font-semibold text-gray-400 py-0 border-l border-transparent">
                                    <ViewListIcon/>LISTA DE VISITAS
                                </div>
                                <div className={styles.cabecalho}>
                                    <div>
                                        <input type="text" placeholder="Pesquisar" onChange={handlePesquisar} style={{width: '150%'}} value={pesquisar} className="border-2 rounded-lg w-full h-8 px-4"/>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <span style={{fontSize: 11.5}}>De: </span>
                                            <input type="date" placeholder="Pesquisar" onChange={handleDataIni} value={dataIni} className="border-2 ml-1 rounded-lg w-full h-8 px-4" style={{fontSize: 11.5}}/>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <span style={{fontSize: 11.5}} className="ml-2">até: </span>
                                            <input type="date" placeholder="Pesquisar" onChange={handleDataFim} value={dataFim} className="border-2 ml-2 rounded-lg w-full h-8 px-4" style={{fontSize: 11.5}}/>
                                        </div>
                                    </div>
                                    <div>
                                        <select className="border-2 rounded-lg w-full h-8 px-4" style={{fontSize: 11.5}} onChange={handleRegistros} value={registros}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                                <p className="mt-2 mb-2" style={{fontSize: 11.5}}>registros: {clientes.length}</p>
                                <div className="max-w-full overflow-x-auto">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className="bg-gray-600 text-center">
                                            <th className="w-1/6 min-w-[360px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-l border-gray-600">
                                                CLIENTE
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                REPRESENTANTE
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                OBSERVAÇÃO
                                            </th>
                                            <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1">
                                                DATA
                                            </th>   
                                            <th className="w-1/1 min-w-[160px] text-sm font-semibold text-white py-1 lg:py-1 px-1 lg:px-1 border-r border-gray-600">
                                                AÇÕES
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clientes.filter((cliente) => {
                                                        if(pesquisar === ''){
                                                            return cliente
                                                        }
                                                        if(pesquisar !== '' && cliente.cliente?.fantasia.toLowerCase().includes(pesquisar.toLowerCase())){
                                                            return cliente
                                                        }
                                                        if(pesquisar !== '' && cliente.cliente?.nome.toLowerCase().includes(pesquisar.toLowerCase())){
                                                            return cliente
                                                        }
                                                        if(pesquisar !== '' && cliente.representante?.nome.toLowerCase().includes(pesquisar.toLowerCase())){
                                                            return cliente
                                                        }
                                                    }).map((cliente, index) => (
                                            index < registros &&
                                            <tr key={index} className={`hover:text-blue-500 ${index % 2 == 0 && 'bg-gray-100'}`}>
                                                <td style={{fontSize: "11.5px"}} className="text-center uppercase border-b border-l border-gray-300">
                                                    {!cliente.cliente?.fantasia ? cliente.cliente?.nome : cliente.cliente?.fantasia}
                                                </td>
                                                <td style={{fontSize: "11.5px"}} className="text-center uppercase border-b border-gray-300">
                                                    {cliente.representante?.nome}
                                                </td>
                                                <td style={{fontSize: "11.5px"}} className="text-center border-b border-gray-300">
                                                    {cliente?.obs}
                                                </td>
                                                <td style={{fontSize: "11.5px"}} className="text-center border-b border-gray-300">
                                                    {cliente?.data_hora}
                                                </td>
                                                <td className="text-center py-0 border-b border-r border-gray-300">
                                                    <Stack direction="row" spacing={1} style={{justifyContent: 'center'}}>
                                                        <IconButton aria-label="delete" color="error">
                                                            <Excluir redirect="listar-visitas" url={Apis.urlDeletarVisita} id={cliente.id} component="Visitas" texto={`Deseja Realmente excluir a visita ao cliente ${cliente.cliente?.nome}`}/>
                                                        </IconButton>
                                                    </Stack>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        else{
            return(
                <>
                    <section className="bg-white py-20 lg:py-[120px]">
                        <div>
                            <Box sx={styleDataFiltro}>
                                <Typography id="modal-modal-title" className="text-center" variant="h6" component="h2">
                                    Nenhum registro foi encontrado, Informa a data inicial e final
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <div>
                                        <input className="border-2 rounded-lg w-full h-10 px-4" type="date" onChange={handleDataIni} value={dataIni}/>
                                    </div>
                                    <div>
                                        <input className="border-2 rounded-lg w-full mt-5 h-10 px-4" type="date" onChange={handleDataFim} value={dataFim}/>
                                    </div>
                                </Typography>
                            </Box>
                        </div>
                    </section>
                </>
            )
        }
    }
}
export default ListaVisitas;