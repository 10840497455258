import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../loading/Loading";
import Apis from "../../Apis";
import Checkbox from '@mui/material/Checkbox';
import swal from "sweetalert";
import FormControlLabel from '@mui/material/FormControlLabel';
const AlterarFormaPagamento = () => {
    const [parcelamento, setParcelamento] = useState(false);
    const [descricao, setDescricao] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const parametro = useParams();
    const handleParcelamento = (e) => {
        setParcelamento(e.target.checked);
    }

    const handleDescricao = (e) => {
        setDescricao(e.target.value);
    }

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }

    useEffect(() => {
        setLoading(true)
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify({"id": parametro.id})
        };
        fetch(Apis.urlListarOneFormaPagamento, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if(result.retorno.sucesso){
                    setDescricao(result.registros[0].descricao)
                    setParcelamento(result.registros[0].parcelamento === '0' ? false : true)
                }else{
                    alertaErro(result.retorno.mensagem)
                    navigate("/listar-forma-pagamento")
                }
                setLoading(false)
            })
    }, []);
    
    const handleSubmit = () => {
        if(descricao !== ''){
            setLoading(true)
            const requestOptions = {
                method: 'PUT',
                headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
                body: JSON.stringify({"id": parametro.id, "descricao": descricao, "parcelamento": parcelamento === true ? 1 : 0})
            };
            fetch(Apis.urlAlterarFormaPagamento, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if(result.retorno.sucesso){
                        navigate(`/alerta-sucesso/${result.retorno.mensagem}&listar-forma-pagamento`);
                    }else{
                        alertaErro(result.retorno.mensagem)
                    }
                    setLoading(false)
                })
        }else{
            alertaErro('O campo descrição é obrigatório.')
        }
    }
    if(loading){
        return(
            <Loading/>
        )
    }else{
        return(
            <>
                <div style={{padding: 15, paddingTop: 0}}>
                    <div className="flex items-center w-full justify-center h-screen">
                        <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
                            <div className="flex flex-col items-center space-y-4">
                                <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">Alteração de Forma de Pagamento</h1>
                                <p className="text-sm text-gray-500 text-center w-6/6">Informe os dados solicitados para realizar a alteração da forma de pagamento.</p>
                                <label className="w-full">Descrição:
                                    <input type="text" placeholder="Descrição" onChange={handleDescricao} value={descricao} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                                </label>
                                <label className="border-2 border-gray-300 rounded-lg w-full h-12 px-4" st>
                                    <FormControlLabel checked={parcelamento} value={parcelamento} control={<Checkbox onChange={handleParcelamento} value={parcelamento}/>} label="Permitir Parcelamento no Pedido" labelPlacement="end"/>
                                </label>
                                <button onClick={handleSubmit} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Alterar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default AlterarFormaPagamento;