import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { useNavigate } from 'react-router-dom';
const ButtonAdd = (props) => {
    const navigate = useNavigate();
    const handlePagina = () => {
        navigate(`/${props.pagina}`)
    }
    return(
        <Tooltip title={`Adicionar ${props.nome}`}>
            <Box onClick={handlePagina} style={{position: 'fixed', bottom: 20, right: 20}}>
                <Fab color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </Box>   
        </Tooltip> 
    )
}
export default ButtonAdd;