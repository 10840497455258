import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Loading from "../../loading/Loading";
import Apis from "../../Apis";
import swal from "sweetalert";

const CadastrarProduto = () => {
    const [codigo, setCodigo] = useState('');
    const [unidadeBd, setUnidadeBd] = useState([]);
    const [descricao, setDescricao] = useState('');
    const [idUnidade, setIdUnidade] = useState('');
    const [siglaUnidade, setSiglaUnidade] = useState('');
    const [preco, setPreco] = useState('');
    const [status, setStatus] = useState('');
    const [estoque, setEstoque] = useState('');
    const [tipo, setTipo] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleCodigo = (e) => {
        setCodigo(e.target.value);
    }
    const handleDescricao = (e) => {
        setDescricao(e.target.value);
    }
    const handleIdUnidade = (e) => {
        setIdUnidade(e.target.value);
    }
    const handlePreco = (e) => {
        setPreco(e.target.value);
    }
    const handleStatus = (e) => {
        setStatus(e.target.value);
    }
    const handleEstoque = (e) => {
        setEstoque(e.target.value);
    }
    const handleTipo = (e) => {
        setTipo(e.target.value);
    }

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }

    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
            body: JSON.stringify("")
        };
        fetch(Apis.urlListarUnidades, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    setUnidadeBd(result.registros)
                    setLoading(false);
                })
    }, [])
    const handleSubmit = () => {
        if(codigo === '' || descricao === '' || idUnidade === '' || preco === '' || status === '' || estoque === '' || tipo === ''){
            alertaErro('Todos os campos devem ser preenchidos!')
        }
        if(codigo !== '' && descricao !== '' && idUnidade !== '' && preco !== '' && status !== '' && estoque !== '' && tipo !== ''){
            setLoading(true)
            const data = { "codigo": codigo, "descricao": descricao, "unidade": siglaUnidade, "unidade_id": idUnidade, "preco": preco, "status": status, "estoque": estoque, "tipo": tipo, "meu_id": "0"};
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}`},
                body: JSON.stringify(data)
            };
            fetch(Apis.urlCadastrarProdutos, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if(result.retorno.sucesso){
                        navigate(`/alerta-sucesso/${result.retorno.mensagem}&listar-produtos`);
                    }else{
                        alertaErro(result.retorno.mensagem);
                    }
                    setLoading(false)
                })
        }
    }
    if(idUnidade === "cadastrarUnidade"){
        navigate(`/cadastrar-unidade/1`)
    }
    if(loading){
        return(
            <Loading/>
        )
    }
    else{
        return(
            <>
                <div style={{padding: 15, paddingTop: 0}}>
                    <div className="flex items-center w-full justify-center h-screen">
                        <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
                            <div className="flex flex-col items-center space-y-4">
                                <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">Cadastro de Produtos</h1>
                                <p className="text-sm text-gray-500 text-center w-6/6">Informe os dados do produto para realizar o cadastro.</p>
                                <label className="w-full">Código:
                                    <input type="text" placeholder="Código" onChange={handleCodigo} value={codigo} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                                </label>
                                <label className="w-full">Descrição:
                                    <input type="text" placeholder="Descrição" onChange={handleDescricao} value={descricao} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                                </label>
                                {unidadeBd ?
                                    <label className="w-full">Unidade:
                                        <select onChange={handleIdUnidade} value={idUnidade} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4">
                                            <option selected value="vazio"> - selecione a unidade - </option>
                                            {unidadeBd.map((unidades) => (
                                                <option value={unidades.id} key={unidades.id}>{unidades.sigla}</option>
                                            ))}
                                            <option value="cadastrarUnidade">Cadastrar unidade</option>
                                        </select>
                                    </label>
                                        :
                                    <Link to={`/cadastrar-unidade/1`} className="w-full">
                                        <button className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Cadastrar Unidade</button>
                                    </Link>
                                }
                                <label className="w-full">Preço:
                                    <input type="number" placeholder="Preço" onChange={handlePreco} value={preco} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                                </label>
                                <label className="w-full">Status:
                                    <select onChange={handleStatus} value={status} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4">
                                        <option selected value="vazio"> - selecione o status - </option>
                                        <option value="0" >Ativo</option>
                                        <option value="1" >Inativo</option>
                                    </select>
                                </label>
                                <label className="w-full">Quantidade em estoque:
                                    <input type="text" placeholder="Quantidade em estoque" onChange={handleEstoque} value={estoque} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                                </label>
                                <label className="w-full">Tipo:
                                    <select onChange={handleTipo} value={tipo} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4">
                                        <option selected value="vazio">- selecione o tipo - </option>
                                        <option value="0" >Mercadoria para Revenda</option>
                                        <option value="1" >Matéria-Prima</option>
                                        <option value="2" >Embalagem</option>
                                        <option value="3" >Produto em Processo</option>
                                        <option value="4" >Produto Acabado</option>
                                        <option value="5" >Subproduto</option>
                                        <option value="6" >Produto Intermediário</option>
                                        <option value="7" >Material de Uso e Consumo</option>
                                        <option value="8" >Ativo Imobilizado</option>
                                        <option value="9" >Serviços</option>
                                        <option value="10" >Outros insumos</option>
                                        <option value="99" >Outras</option>
                                    </select>
                                </label>
                                <button onClick={handleSubmit} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Cadastrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default CadastrarProduto;