import React, { useState } from "react";
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from "react-router-dom";
import Apis from "../../../Apis";
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from "../../../../services/firebase.js";
import swal from "sweetalert";

const CadastrarEmpresa = () => {
    const [email, setEmail] = useState('');
    const [nome, setNome] = useState('');
    const [imagemLogin, setImagemLogin] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const navigate = useNavigate();

    const [user, setUser] = useState([])
    const handleGoogleSign = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
        .then((result) =>{
            setUser(result.user)
            console.log(result.user)
            setEmail(result.user.email)
            setNome(result.user.displayName)
            setImagemLogin(result.user.photoURL)
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const alertaErro = (texto) => {
        swal({
            title: "Atenção",
            text: texto,
            icon: "error",
            dangerMode: true,
            className: 'alertas-cad-empresa'
        })
    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleNome = (e) => {
        setNome(e.target.value);
    }

    const handleCnpj = (e) => {
        setCnpj(e.target.value);
    }

    const handleSenha = (e) => {
        setSenha(e.target.value);
    }

    const handleConfirmarSenha = (e) => {
        setConfirmarSenha(e.target.value);
    }

    const handleSubmit = () => {
        if(email === '' || nome === '' || senha === '' || confirmarSenha === '' || senha !== confirmarSenha){
            if(senha !== confirmarSenha){
                alertaErro('Os campos senha e confirmar senha devem ser iguais')
            }
            if(senha === confirmarSenha){
                alertaErro('Todos os campos devem ser preenchidos!')
            }
        }
        if(email !== '' && nome !== '' && senha !== '' && confirmarSenha !== ''){
            const data = {"email": email, "nome": nome, "cnpj": cnpj, "senha": senha, "imagem_login" : imagemLogin};
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            };
            fetch(Apis.urlCadastrarEmpresas, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if(result.retorno.sucesso){
                        navigate(`/alerta-suces//${result.retorno.mensagem}&login`);
                    }else{
                        alertaErro(result.retorno.mensagem);
                    }
                })
        }
    }
    return(
        <>
            <div className="container">
                <div className="flex items-center w-full justify-center h-screen">
                    <div className="bg-white w-full rounded-2xl border shadow-xl p-10 max-w-lg">
                        <div className="flex flex-col items-center space-y-4">
                            {user.photoURL && <img src={imagemLogin} alt="Foto do usuário" style={{width: '140px', height: '140px', borderRadius: '70px'}}/>}
                            {/* <strong>{user.displayName}</strong> */}
                            {/* <small>{user.email}</small> */}
                            <h1 className="font-bold text-2xl text-gray-700 w-6/6 text-center">Cadastro de Empresa</h1>
                            <p className="text-sm text-gray-500 text-center w-6/6">Informe os dados solicitados para realizar o cadastro da  empresa.</p>
                            <label className="w-full">Email:
                                <input type="text" placeholder="Email" onChange={handleEmail} value={email} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>    
                            <label className="w-full">Nome da empresa:
                                <input type="text" placeholder="Nome da empresa" onChange={handleNome} value={nome} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>    
                            <label className="w-full">CNPJ da empresa:
                                <input type="text" placeholder="CNPJ da empresa" onChange={handleCnpj} value={cnpj} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>    
                            <label className="w-full">Senha:
                                <input type="password" placeholder="Senha" onChange={handleSenha} value={senha} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>    
                            <label className="w-full">Confirmar senha:
                                <input type="password" placeholder="Confirmar senha" onChange={handleConfirmarSenha} value={confirmarSenha} className="border-2 border-gray-300 rounded-lg w-full h-12 px-4"/>
                            </label>    
                            <button onClick={handleSubmit} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 w-full">Cadastrar</button>
                            <button onClick={handleGoogleSign} className="bg-blue-400 text-white rounded-md font-semibold px-4 py-3 flex justify-center w-full items-center"><GoogleIcon fontSize="small" /><span className="ml-1">Cadastrar com o Google</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CadastrarEmpresa;